export function formatDate(value) {
  const date = typeof value === typeof "" ? new Date(value) : value;
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}
export function formatCurrency(value) {
  return new Intl.NumberFormat("hr-HR", { minimumFractionDigits: 2 }).format(
    value
  );
}
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function formatNaslovnicaData(obj) {
  const data = {
    ...obj,
    Nadzor1: {
      ...obj.nadzor1.osoba,
      value: obj.nadzor1.osoba.ID,
      label: obj.nadzor1.osoba.Ime + " " + obj.nadzor1.osoba.Prezime,
    },
    Nadzor2: {
      ...obj.nadzor2.osoba,
      value: obj.nadzor2.osoba.ID,
      label: obj.nadzor2.osoba.Ime + " " + obj.nadzor2.osoba.Prezime,
    },
    Nadzor3: {
      ...obj.nadzor3.osoba,
      value: obj.nadzor3.osoba.ID,
      label: obj.nadzor3.osoba.Ime + " " + obj.nadzor3.osoba.Prezime,
    },
    Akt1: {
      ...obj.nadzor1.imenovanje,
      value: obj.nadzor1.imenovanje.ID,
      label: obj.nadzor1.imenovanje.naziv,
    },
    Akt2: {
      ...obj.nadzor2.imenovanje,
      value: obj.nadzor2.imenovanje.ID,
      label: obj.nadzor2.imenovanje.naziv,
    },
    Akt3: {
      ...obj.nadzor3.imenovanje,
      value: obj.nadzor3.imenovanje.ID,
      label: obj.nadzor3.imenovanje.naziv,
    },
    ugovor: {
      ...obj.gradevinskaDozvola,
      value: obj.gradevinskaDozvola.ID,
      label: obj.gradevinskaDozvola.naziv,
    },
    AktGlavni: {
      ...obj.glavniNadzor.imenovanje,
      value: obj.glavniNadzor.imenovanje.ID,
      label: obj.glavniNadzor.imenovanje.naziv,
    },
    GlavniNadzor: {
      ...obj.glavniNadzor.osoba,
      value: obj.glavniNadzor.osoba.ID,
      label: obj.glavniNadzor.osoba.Ime + " " + obj.glavniNadzor.osoba.Prezime,
    },
    AktImenovanje: {
      ...obj.voditelj.imenovanje,
      value: obj.voditelj.imenovanje.ID,
      label: obj.voditelj.imenovanje.naziv,
    },
    GlavniInzenjer: {
      ...obj.voditelj.osoba,
      value: obj.voditelj.osoba.ID,
      label: obj.voditelj.osoba.Ime + " " + obj.voditelj.osoba.Prezime,
    },
    Cijena: obj.CijenaBezPdv,
    PocetakPrijave: new Date(obj.DatumPrijave),
    Pocetak: new Date(obj.DatumPocetak),
    Zavrsetak: new Date(obj.DatumZavrsetak),
  };
  return data;
}
export function formatZaposlenik(data) {
  const dataMod = {
    ...data,
    ovg: { ...data.ovg, label: data.ovg.naziv, value: data.ovg.ID },
    oie: { ...data.oie, label: data.oie.naziv, value: data.oie.ID },
  };
  return dataMod;
}
