import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import NumberFormat from "react-number-format";
import updateAction from "./updateAction";
import { useStateMachine } from "little-state-machine";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { Error } from "../styles";

const formatCurrency = (value) =>
  new Intl.NumberFormat("hr-HR", {}).format(value);

const InputForm = ({ from, addStroj, reqError }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors, setValue, getValues } = useForm();

  const onSubmit = (data) => {
    if (from === "edit") {
      console.log("send to edit");
    } else {
      console.log("send to add");
    }
    console.log(data);
    action(data);
    addStroj(data);
  };

  return (
    <>
      <div className="modal-body">
        <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="TipStroja">Tip stroja</Label>
                  <Controller
                    id="TipStroja"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("TipStroja") && "inputError"
                        }`}
                        placeholder="Tip stroja"
                        type="text"
                      />
                    }
                    defaultValue={state.stroj.TipStroja}
                    name="TipStroja"
                    control={control}
                    rules={{ required: "Tip stroja je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="TipStroja" />
                </Col>
                <Col md="6">
                  <Label for="MarkaStroja">Marka stroja</Label>
                  <Controller
                    id="MarkaStroja"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("MarkaStoja") && "inputError"
                        }`}
                        placeholder="MarkaStoja"
                        type="text"
                      />
                    }
                    defaultValue={state.stroj.MarkaStroja}
                    name="MarkaStroja"
                    control={control}
                    rules={{
                      required: "MarkaStroja je obvezno polje",
                    }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="MarkaStroja" />
                </Col>
              </Row>
            </FormGroup>

            <Row className="justify-content-center">
              <Col md="6">
                <Label for="RegistracijskaOznaka">Registracijska oznaka</Label>
                <Controller
                  id="RegistracijskaOznaka"
                  as={
                    <Input
                      className={`form-control-alternative ${
                        errors.hasOwnProperty("RegistracijskaOznaka") &&
                        "inputError"
                      }`}
                      placeholder="Registracijska oznaka"
                      type="text"
                    />
                  }
                  defaultValue={state.stroj.RegistracijskaOznaka}
                  name="RegistracijskaOznaka"
                  control={control}
                  rules={{
                    required: "Registracijska oznaka je obvezno polje",
                  }}
                />

                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="RegistracijskaOznaka"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }} className="justify-content-center">
              <Col md="2">
                <Button
                  color="primary"
                  type="submit"
                  disabled={reqError.message.length !== 0}
                >
                  Potvrdi
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {reqError.message.length !== 0 && (
                  <h4 className="error">{reqError.message}</h4>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <div className="modal-footer"></div>
    </>
  );
};

export default InputForm;
