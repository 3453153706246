import React from "react";
import { authenticationService } from "../../services/authentication";
import { formatDateToString, b64toBlob } from "../../utils/functions";
import DeleteModal from "./DeleteModal";
import { Button } from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import style from "./GradevinskaKnjiga.module.scss";
import Table from "../Table/Table";
import FormModal from "./FormModal";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";
import { saveAs } from "file-saver";

const GradevinskaKnjiga = ({ user, activePage }) => {
  const [rowToEdit, setRowToEdit] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [modals, setModals] = React.useState({
    Delete: false,
  });
  const { action } = useStateMachine(updateAction);

  const [open, setOpen] = React.useState({ state: false, from: "" });
  const [reqError, setReqError] = React.useState({ state: false, message: "" });

  const { status, data, error, isLoading, isFetching } = useQuery(
    "knjiga",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=DnevnikItemGets",
        [{ user: "igor", naslovnicaID: activePage.id }]
      );
      const parsedData = JSON.parse(data);
      const formatData = parsedData.filter((item) => {
        if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
          return true;
        } else return null;
      });
      console.log(formatData);
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount >= 3) {
            authenticationService.logout();
          } else return true;
        }
      },
    }
  );

  const [addKnjiga] = useMutation(
    async (dataToAdd) => {
      const ZaposleniciCount = dataToAdd.Zaposlenici.reduce(function (obj, v) {
        if (open.from === "edit") {
          obj[v.label] = (obj[v.label] || 0) + 1;
        } else {
          obj[v.Struka] = (obj[v.Struka] || 0) + 1;
        }
        return obj;
      }, {});
      const StrojeviCount = dataToAdd.Strojevi.reduce(function (obj, v) {
        if (open.from === "edit") {
          obj[v.label] = (obj[v.label] || 0) + 1;
        } else {
          obj[v.TipStroja] = (obj[v.TipStroja] || 0) + 1;
        }
        return obj;
      }, {});

      const d = {
        ...dataToAdd,
        ID: open.from === "edit" ? rowToEdit : 0,
        OrganizationID: user.orgID,
        NaslovnicaID: activePage.id,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
        Zaposlenici: ZaposleniciCount,
        Strojevi: StrojeviCount,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=DnevnikItemAddEdit",
        [{ user: "igor", d }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("knjiga");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("knjiga");
          if (open.state && !isFetching) {
            setOpen({ ...open, state: false });
          }
        }
      },
    }
  );
  const [deleteKnjiga] = useMutation(
    async () => {
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=DnevnikItemDelete",
        [{ user: "igor", ID: rowToDelete.ID }]
      );
      return res;
    },
    {
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("knjiga");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("knjiga");
          if (modals.Delete && !isFetching) {
            setModals({ ...modals, Delete: false });
          }
        }
      },
    }
  );
  const cols = React.useMemo(
    () => [
      {
        accessor: "Struka",
        className: "alignCenter",
        Header: "Struka",
      },
      {
        accessor: "DatumUpis",
        className: "alignCenter",
        Header: "Datum upisa",
        Cell: (props) => formatDateToString(props.cell.value),
      },
      {
        accessor: "VremenskiUvjeti",
        Header: "Vremenski uvjeti",
        className: "alignCenter",
      },
      {
        accessor: "Temperatura1",
        className: "alignCenter",
        Header: "Temperatura 1",
      },
      {
        accessor: "Temperatura2",
        Header: "Temperatura 2",
        className: "alignCenter",
      },
      {
        accessor: "Vodostaj",
        Header: "Vodostaj",
        className: "alignCenter",
      },
      {
        accessor: "Zaposlenici",
        Header: "Zaposlenici",
        className: "alignCenter",
        Cell: (props) => {
          return props.cell.value.map((item) => item.label + ", ");
        },
      },
      {
        accessor: "Strojevi",
        Header: "Strojevi",
        className: "alignCenter",
        Cell: (props) => {
          return props.cell.value.map((item) => item.label + ", ");
        },
      },
      {
        accessor: "UpisVoditelja",
        Header: "Upis voditelja",
        className: "alignCenter",
      },
      {
        accessor: "UpisNadzora",
        Header: "Upis nadzora",
        className: "alignCenter",
      },
      {
        accessor: "Uredi",
        Header: "Uredi",
        className: "alignCenter",
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.editButton}
                type="button"
                onClick={(e) => {
                  setRowToEdit(props.row.original.ID);
                  action(props.row.original);
                  console.log(props.row.original);
                  setOpen({ state: true, from: "edit" });
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            );
          return null;
        },
      },
      {
        accessor: "Obrisi",
        Header: "Obriši",
        disableSortBy: true,
        className: "alignCenter",
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.deleteButton}
                type="button"
                onClick={() => {
                  setModals({ ...modals, Delete: true });
                  setRowToDelete(props.row.original);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            );
          return null;
        },
      },
    ],
    [modals, action]
  );
  const generatePDF = async () => {
    const res = await handlePostRequest("https://localhost:3001/get-pdf", [
      { data, name: "gradevinaListovi" },
    ])
      .then((res) => {
        const url = window.URL.createObjectURL(
          b64toBlob(res.data, "application/pdf")
        );
        saveAs(url, `GradevinskiDnevnikListovi.pdf`);
        //setIsDownloading(false);
      })
      .catch((err) => console.log(err));
    console.log(res);
  };
  return (
    <>
      {status === "loading" || status === "error" ? (
        <div className="d-flex mx-auto justify-content-center">
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : (
        <div>
          <div className="main-content">
            <section className={style.buttons}>
              <Button
                color="primary"
                onClick={() => {
                  action({
                    DatumUpis: new Date(),
                    Strojevi: null,
                    Struka: "",
                    Temperatura1: "",
                    Temperatura2: "",
                    UpisNadzora: "",
                    UpisVoditelja: "",
                    Vodostaj: "",
                    VremenskiUvjeti: "",
                    Zaposlenici: null,
                  });
                  setOpen({ from: "add", state: !open.state });
                }}
              >
                Novi unos
              </Button>
              <Button color="primary" onClick={generatePDF}>
                PDF
              </Button>
            </section>

            <Table
              columns={cols}
              initialSort={[{ id: "Naziv", desc: false }]}
              data={data}
            ></Table>
          </div>
        </div>
      )}
      <FormModal
        open={open}
        user={user}
        setOpen={setOpen}
        add={addKnjiga}
        reqError={reqError}
      />
      {modals.Delete ? (
        <DeleteModal
          open={modals}
          setOpen={setModals}
          rowToDelete={rowToDelete}
          delFunc={deleteKnjiga}
        ></DeleteModal>
      ) : null}
    </>
  );
};

export default GradevinskaKnjiga;
