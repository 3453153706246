import React from "react";
import { StateMachineProvider } from "little-state-machine";
import { Modal } from "reactstrap";
import "./Imenovanja.scss";
import InputForm from "./InputForm";

export default function Imenovanja({
  aktModal,
  setAktModal,
  aktovi,
  add,
  setAkt,
}) {
  return (
    <>
      <Modal
        isOpen={aktModal.open}
        toggle={() => setAktModal({ ...aktModal, open: false })}
        aria-labelledby="akt 1 modal"
        aria-describedby="form for akt 1 inputs"
      >
        <div className="modal-header">
          <h3 className="modal-title" id="exampleModalLabel">
            Novi akt
          </h3>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setAktModal({ ...aktModal, open: !aktModal.open })}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <StateMachineProvider>
          <InputForm
            aktModal={aktModal}
            setAktModal={setAktModal}
            aktovi={aktovi}
            add={add}
            setAkt={setAkt}
          />
        </StateMachineProvider>
      </Modal>
    </>
  );
}
