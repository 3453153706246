import React from "react";
import { Modal, Button } from "reactstrap";
import { handlePostRequest } from "../../helpers/handle-response";

const DeleteModal = ({ open, setOpen, rowToDelete, delFunc }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size={"lg"}
      isOpen={open.Delete}
      toggle={() => setOpen({ ...open, Delete: !open.Delete })}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Obriši organizaciju
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen({ ...open, Delete: !open.Delete })}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="mx-auto">
        <h2 className="ml-4 ml-sm-0">Jeste li sigurni da želite obrisati organizaciju?</h2>
        <ul>
          <li>
            Naziv:{" "}
            <span className="font-weight-bold"> {rowToDelete.Naziv}</span>
          </li>
          <li>
            OIB: <span className="font-weight-bold"> {rowToDelete.OIB}</span>
          </li>
          <li>
            IBAN: <span className="font-weight-bold"> {rowToDelete.IBAN}</span>
          </li>
          <li>
            Odgovorna osoba:{" "}
            <span className="font-weight-bold">
              {rowToDelete.OdgovornaOsoba}
            </span>
          </li>
          <li>
            Struka:{" "}
            <span className="font-weight-bold"> {rowToDelete.Struka}</span>
          </li>
          <li>
            Adresa:{" "}
            <span className="font-weight-bold"> {rowToDelete.Adresa}</span>
          </li>
          <li>
            Mjesto:{" "}
            <span className="font-weight-bold"> {rowToDelete.Mjesto}</span>
          </li>
          <li>
            Država:{" "}
            <span className="font-weight-bold"> {rowToDelete.Drzava}</span>
          </li>
          <li>
            Email:{" "}
            <span className="font-weight-bold"> {rowToDelete.Email}</span>
          </li>
        </ul>
        <Button
          className="mx-auto my-2 d-flex"
          color="danger"
          onClick={delFunc}
        >
          Obriši
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
