import React from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import FormModal from "./FormModal";

import styled from "styled-components";

const NewNaslovnica = () => {
  const [open, setOpen] = React.useState(false);
  let history = useHistory();
  return (
    <div>
      <Container>
        <Row>
          <Col className="col-sm" style={{ display: "flex" }}>
            <Title>Nova naslovnica</Title>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Button color="primary" onClick={() => setOpen(!open)}>
              Unesi podatke
            </Button>
          </Col>
          <Col>
            <Button onClick={() => history.push("/")}>Natrag</Button>
          </Col>
        </Row>
        <Row>
          <Col
            className="col-sm"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span>Html goeshere</span>
          </Col>
        </Row>
      </Container>
      <FormModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default NewNaslovnica;

const Title = styled.h1`
  margin-top: 20px;
  margin-bottom: 50px;
  position: relative;
  padding: 0 0.5em;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35%;
    background-color: lightblue;
    z-index: -10;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
  }
`;
