import React from "react";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { makeStyles } from "@material-ui/core/styles";
import { useStateMachine } from "little-state-machine";
import updateAction from "./Form/updateAction";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    textAlign: "center",
  },
}));

const LastStep = ({ setOpen, addStatus, isSuccess }) => {
  const classes = useStyles();
  const { action } = useStateMachine(updateAction);

  React.useEffect(() => {
    console.log("action called");
    action({
      Akt1: null,
      Akt2: null,
      Akt3: null,
      AktGlavni: null,
      AktImenovanje: null,
      GlavniInzenjer: null,
      GlavniNadzor: null,
      ugovor: null,
      Gradevina: "",
      Investitor: "",
      Izvodac: "",
      Klasa: "",
      Cijena: "",
      Naziv: "",
      Nadnevak: new Date(),
      Nadzor1: "",
      Nadzor2: "",
      Nadzor3: "",
      NazivSjediste: "",
      Pocetak: new Date(),
      PocetakPrijave: new Date(),
      Urbroj: "",
      Zavrsetak: new Date(),
    });
  }, [action, isSuccess]);
  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
      {addStatus === "error" ? (
        <>
          <ErrorOutlineIcon
            style={{ alignSelf: "center", fontSize: "4rem", fill: "#DC0E4E" }}
          />
          <Typography className={classes.instructions}>
            Pogreška. Pokušajte ponovo.
          </Typography>
        </>
      ) : null}
      {addStatus === "idle" || addStatus === "loading" ? (
        <div className="d-flex mx-auto justify-content-center">
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : null}
      {addStatus === "success" ? (
        <>
          <CheckCircleIcon
            style={{ alignSelf: "center", fontSize: "4rem", fill: "#4caf50" }}
          />
          <Typography className={classes.instructions}>
            Naslovnica uspješno spremljena
          </Typography>
        </>
      ) : null}
    </div>
  );
};

export default LastStep;
