import React from "react";
import { Button } from "reactstrap";
import style from "./Organizacije.module.scss";
import Table from "../Table/Table";
import FormModal from "./FormModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteModal from "./DeleteModal";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";

const Organizacije = ({ user }) => {
  const [rowToEdit, setRowToEdit] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [modals, setModals] = React.useState({
    Delete: false,
  });
  const { action, state } = useStateMachine(updateAction);
  const [open, setOpen] = React.useState({ from: "", state: false });
  const [reqError, setReqError] = React.useState({ state: false, message: "" });

  const { status, data, error, isLoading, isFetching, refetch } = useQuery(
    "organizacije",
    async () => {
      console.log("called async get");
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=OrganizacijaGets",
        [{ user: "igor", orgID: user.orgID }]
      );
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        });
      console.log(formatData);
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );

  const [addOrganizacija] = useMutation(
    async (dataToAdd) => {
      const o = {
        ...dataToAdd,
        ID: open.from === "edit" ? rowToEdit : 0,
        OrganizationID: user.orgID,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=OrganizacijaAddEdit",
        [{ user: "igor", o }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("organizacije");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("organizacije");
          // FIX CLOSE MODAL
          if (open.state) {
            setOpen({ ...open, state: false });
          }
        }
      },
    }
  );
  const [deleteOrganizacija] = useMutation(
    async () => {
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=OrganizacijaDelete",
        [{ user: "igor", ID: rowToDelete.ID }]
      );
      return res;
    },
    {
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("organizacije");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("organizacije");
          if (modals.Delete && !isFetching) {
            setModals({ ...modals, Delete: false });
          }
        }
      },
    }
  );
  const cols = React.useMemo(
    () => [
      /*   {
        accessor: "Struka",
        className: "alignCenter",
        Header: "Struka",
      }, */
      {
        accessor: "Naziv",
        className: "alignCenter",
        Header: "Naziv",
      },
      {
        accessor: "OIB",
        Header: "OIB",
        className: "alignCenter",
      },
      {
        accessor: "IBAN",
        className: "alignCenter",
        Header: "IBAN",
      },
      {
        accessor: "OdgovornaOsoba",
        Header: "Odgovorna osoba",
        className: "alignCenter",
      },
      {
        accessor: "Struka",
        className: "alignCenter",
        Header: "Struka",
        colClass: style.alignCenter,
      },
      {
        Header: "Adresa",
        accessor: "Adresa",
        className: "alignCenter",
        colClass: style.alignCenter,
      },
      {
        Header: "Mjesto",
        accessor: "Mjesto",
        className: "alignCenter",
        colClass: style.alignCenter,
      },
      {
        Header: "Država",
        accessor: "Drzava",
        className: "alignCenter",
        colClass: style.alignCenter,
      },
      {
        Header: "Email",
        accessor: "Email",
        className: "alignCenter",
        colClass: style.alignCenter,
      },

      {
        accessor: "Uredi",
        Header: "Uredi",
        className: "alignCenter",
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.editButton}
                type="button"
                onClick={(e) => {
                  setRowToEdit(props.row.original.ID);
                  console.log(props.row.original);
                  action(props.row.original);
                  setOpen({ state: true, from: "edit" });
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            );
          return null;
        },
      },
      {
        accessor: "Obrisi",
        Header: "Obriši",
        disableSortBy: true,
        className: "alignCenter",
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.deleteButton}
                type="button"
                onClick={() => {
                  setModals({ ...modals, Delete: true });
                  setRowToDelete(props.row.original);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            );
          return null;
        },
      },
    ],
    [modals, action]
  );

  return (
    <>
      {status === "loading" ? (
        <div className="d-flex mx-auto justify-content-center mt-2">
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : (
        <div>
          <div className="main-content">
            <section className={style.buttons}>
              <Button
                color="primary"
                onClick={() => {
                  setOpen({ state: !open.state, from: "add" });
                  action({
                    Adresa: "",
                    Drzava: "",
                    Email: "",
                    IBAN: "",
                    Mjesto: "",
                    Naziv: "",
                    OIB: "",
                    OdgovornaOsoba: "",
                    Struka: "",
                  });
                }}
              >
                Dodaj novu
              </Button>
            </section>

            <Table
              columns={cols}
              initialSort={[{ id: "Naziv", desc: false }]}
              data={data}
            ></Table>
          </div>
        </div>
      )}
      <FormModal
        open={open}
        setOpen={setOpen}
        add={addOrganizacija}
        reqError={reqError}
      />
      <DeleteModal
        open={modals}
        setOpen={setModals}
        rowToDelete={rowToDelete}
        delFunc={deleteOrganizacija}
      ></DeleteModal>
    </>
  );
};

export default Organizacije;
