import React from "react";
import ReactDatetime from "react-datetime";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import updateAkt from "../../Akt/updateAction";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import hr from "moment/locale/hr";
import { Error } from "../../styles";
import * as yup from "yup";
import { handlePostRequest } from "../../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";
import Imenovanja from "../../Akt/Imenovanja";

const customStyles = {
  control: (styles, { data }) => ({
    ...styles,
    boxShadow:
      " 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important",
    border: "0 !important",
    transition: " box-shadow 0.15s ease !important",
  }),
};
const DateSchema = yup.object().shape({
  Gradevina: yup.string().required("Građevina je obvezno polje"),
  ugovor: yup.object().shape({
    value: yup.string().required("Ugovor je obvezno polje"),
    label: yup.string().required("Ugovor je obvezno polje"),
  }),
  GlavniInzenjer: yup.object().shape({
    value: yup.string().required("Glavni inženjer je obvezno polje"),
    label: yup.string().required("Glavni inženjer je obvezno polje"),
  }),
  AktImenovanje: yup.object().shape({
    value: yup.string().required("Akt o imenovanju je obvezno polje"),
    label: yup.string().required("Akt o imenovanju je obvezno polje"),
  }),
});

const Gradevina = ({ handleNext, handleBack, zaposlenici, user, size }) => {
  const { control, handleSubmit, errors, getValues, setValue } = useForm({});
  const { action, state } = useStateMachine(updateAction);
  const { action: aktAction } = useStateMachine(updateAkt);
  const [addedAkt, setAddedAkt] = React.useState({ refresh: "" });

  const onSubmit = (data) => {
    action(data);
    handleNext();
  };
  const submitBack = () => {
    action(getValues());
    handleBack();
  };

  const setAkt = (data) => {
    //Find akt first
    const obj = data.find((item) => {
      if (item.naziv === addedAkt.naziv) {
        return item;
      }
    });
    setValue(aktModal.from, { ...obj, label: obj.naziv, value: obj.ID });
  };

  const [reqError, setReqError] = React.useState({});
  const [aktModal, setAktModal] = React.useState({ from: "", open: false });

  const { status, data, error, isFetching, isLoading } = useQuery(
    "aktovi",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=AktoviGets",
        [{ user: "igor", orgID: user.orgID }]
      );
      const parsedData = JSON.parse(data);

      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        })
        .map((item) => {
          return {
            ...item,
            value: item.ID,
            label: item.naziv,
          };
        });
      console.log(formatData);
      if (addedAkt.refresh === "refresh") {
        setAkt(formatData);
        setAddedAkt({ refresh: "" });
      }
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );
  const [addAkt] = useMutation(
    async (dataToAdd) => {
      const a = {
        ID: 0,
        OrganizationID: user.orgID,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
        ...dataToAdd,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=AktoviAddEdit",
        [{ user: "igor", a }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("aktovi");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("aktovi");
          if (aktModal.open && !isFetching) {
            setAktModal({ ...aktModal, open: false });
          }
        }
      },
    }
  );
  return (
    <>
      <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label for="Gradevina">Građevina</Label>
                <Controller
                  id="Gradevina"
                  as={
                    <Input
                      className={`form-control-alternative ${
                        errors.hasOwnProperty("Gradevina") && "inputError"
                      }`}
                      placeholder="Građevina"
                      type="text"
                    />
                  }
                  rules={{
                    required: "Građevina je obvezno polje",
                  }}
                  defaultValue={state.data.Gradevina}
                  name="Gradevina"
                  control={control}
                />
                <ErrorMessage as={Error} errors={errors} name="Gradevina" />
              </Col>
              <Col md="5">
                <Label for="ugovor">Ugovor</Label>
                <Controller
                  id="ugovor"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.ugovor}
                  placeholder="Odaberite ugovor"
                  name="ugovor"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else return value[0];
                  }}
                  control={control}
                  rules={{
                    required: "Ugovor je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={data}
                />
                <ErrorMessage as={Error} errors={errors} name="ugovor" />
              </Col>
              <Col
                md="1"
                style={
                  size.width < 1024
                    ? { marginTop: "1vh" }
                    : errors.hasOwnProperty("ugovor")
                    ? { marginTop: "6vh" }
                    : { marginTop: "6vh" }
                }
              >
                <Button
                  size="sm"
                  title="Dodaj novi akt"
                  type="button"
                  className="mx-auto d-flex"
                  onClick={() => {
                    aktAction({
                      Klasa: "",
                      UrBroj: "",
                      mjesto: "",
                      nadnevak: new Date(),
                      naziv: "",
                    });
                    setAktModal({ from: "ugovor", open: true });
                  }}
                >
                  +
                </Button>
              </Col>
            </Row>
          </FormGroup>

          <Row>
            <Col md="6">
              <Label for="GlavniInzenjer">Glavni inženjer</Label>

              <Controller
                id="GlavniInzenjer"
                as={<Select styles={customStyles} />}
                defaultValue={state.data.GlavniInzenjer}
                placeholder="Odaberite glavnog inženjera"
                name="GlavniInzenjer"
                onChange={(value) => {
                  if (value[1].action === "clear") {
                    return null;
                  } else return value[0];
                }}
                control={control}
                rules={{
                  required: "Glavni inženjer je obvezno polje",
                  min: { value: 1, message: "Error" },
                }}
                options={zaposlenici}
              />
              <ErrorMessage as={Error} errors={errors} name="GlavniInzenjer" />
            </Col>
            <Col md="5">
              <Label for="AktImenovanje">Akt o imenovanju</Label>

              <Controller
                id="AktImenovanje"
                as={<Select styles={customStyles} />}
                defaultValue={state.data.AktImenovanje}
                placeholder="Odaberite akt"
                name="AktImenovanje"
                onChange={(value) => {
                  if (value[1].action === "clear") {
                    return null;
                  } else return value[0];
                }}
                control={control}
                rules={{
                  required: "Akt o imenovanju je obvezno polje",
                  min: { value: 1, message: "Error" },
                }}
                options={data}
              />
              <ErrorMessage as={Error} errors={errors} name="AktImenovanje" />
            </Col>
            <Col
              md="1"
              style={
                size.width < 1024
                  ? { marginTop: "1vh" }
                  : errors.hasOwnProperty("AktImenovanje")
                  ? { marginTop: "6vh" }
                  : { marginTop: "6vh" }
              }
            >
              <Button
                size="sm"
                title="Dodaj novi akt"
                type="button"
                className="mx-auto d-flex"
                onClick={() => {
                  aktAction({
                    Klasa: "",
                    UrBroj: "",
                    mjesto: "",
                    nadnevak: new Date(),
                    naziv: "",
                  });
                  setAktModal({ from: "AktImenovanje", open: true });
                }}
              >
                +
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <Button onClick={submitBack}>Natrag</Button>
              <Button color="primary" type="submit">
                Sljedeći
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Imenovanja
        aktovi={data}
        aktModal={aktModal}
        setAktModal={setAktModal}
        add={addAkt}
        setAkt={setAddedAkt}
      />
    </>
  );
};

export default Gradevina;
