import React from "react";
import { authenticationService } from "../../services/authentication";
import jwt_decode from "jwt-decode";
import { createStore } from "little-state-machine";
import { useWindowSize } from "../../utils/hooks";
import { NavbarBrand, Navbar, Container, Badge, Button } from "reactstrap";
import { Router, Link, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import Login from "../Login/Login";
import Sidebar from "../Sidebar/Sidebar";
import TabMenu from "../TabMenu/TabMenu";
import ProfileMenu from "./ProfileMenu";
import Naslovnica from "../Naslovnica/Naslovnica";
import NaslovnicaNadzor from "../Naslovnica/NaslovnicaNadzor";
import NewNaslovnica from "../Naslovnica/NewNaslovnica";
import Troskovnik from "../Troskovnik/Troskovnik";
import Organizacije from "../Organizacije/Organizacije";
import Strojevi from "../Strojevi/Strojevi";
import Zaposlenici from "../Zaposlenici/Zaposlenici";
import MontazniDnevnik from "../MontazniDnevnik/MontazniDnevnik";
import GradevinskiDnevnik from "../GradevinskiDnevnik/GradevinskiDnevnik";
import GradevinskiDnevnikNadzor from "../GradevinskiDnevnik/NadzorItem";
import GradevinskaKnjiga from "../GradevinskaKnjiga/GradevinskaKnjiga";
import ChooseRole from "../ChooseRole/ChooseRole";
import Akt from "../Akt/Akt";
import "./App.scss";

const history = createBrowserHistory();

createStore({
  data: { Investitor: "", Izvodac: "" },
  troskovnikItem: { Investitor: "", Izvodac: "" },
  organizacija: {},
  stroj: {},
  zaposlenik: {},
  montazni: {},
  gradevinski: {},
  akt: {},
});

function App() {
  const [aktModal, setAktModal] = React.useState({ from: "", open: false });
  const [activePage, setActivePage] = React.useState({ name: "", id: 0 });
  const [activeTab, setActiveTab] = React.useState(1);

  const [user, setUser] = React.useState();

  const decoded = user && jwt_decode(user.token);

  React.useEffect(() => {
    authenticationService.user.subscribe((x) => setUser(x));
    authenticationService.naslovnica.subscribe((x) => {
      if (x) {
        setActivePage(x);
      } else {
        authenticationService.setNaslovnica({ name: "", id: 0 });
        setActivePage({ name: "", id: 0 });
      }
    });
  });

  const handleSetActive = React.useCallback((name, id) => {
    return setActivePage({ name: name, id: id });
  }, []);
  const size = useWindowSize();
  return (
    <div className="App">
      <Router history={history}>
        <Navbar
          className="navbar-horizontal navbar-dark bg-default"
          expand="lg"
        >
          <Container fluid style={{ justifyContent: "start" }}>
            <NavbarBrand
              className="customNavHeader"
              style={{ marginRight: 5, textTransform: "unset", fontSize: 18 }}
              tag={Link}
              onClick={() => {
                setActiveTab(1);
              }}
              to={
                activePage.name.length !== 0
                  ? user && user.role === "nadzor"
                    ? "/gradevinski-dnevnik"
                    : "/troskovnici"
                  : "/"
              }
            >
              BuildIng
            </NavbarBrand>
            {activePage.name.length !== 0 ? (
              <h5 style={{ color: "white", marginBottom: 0, marginLeft: 10 }}>
                Aktivna naslovnica:{" "}
                <Badge style={{ fontSize: 12 }}>{activePage.name}</Badge>
              </h5>
            ) : null}
            <span
              style={{
                fontWeight: 600,
                textTransform: "uppercase",
                fontSize: ".875rem",
                letterSpacing: ".05px",
                color: "rgba(255, 255, 255, 0.65)",
              }}
            ></span>
            {activePage.name.length !== 0 ? (
              <Button
                size="sm"
                style={
                  size.width <= 767
                    ? { display: "none" }
                    : { marginLeft: "auto" }
                }
                onClick={() => {
                  setActivePage({ name: "", id: 0 });
                  authenticationService.setNaslovnica({ name: "", id: 0 });
                  history.push("/");
                }}
              >
                Izađi iz naslovnice
              </Button>
            ) : null}
            {size.width > 767 ? (
              user ? (
                <ProfileMenu
                  user={user}
                  isMobile={size.width <= 767 ? true : false}
                  marginLeft={
                    activePage.name.length === 0 || size.width <= 767
                      ? true
                      : false
                  }
                />
              ) : null
            ) : null}
          </Container>
        </Navbar>
        {size.width > 767 ? null : (
          <Sidebar
            user={user}
            activePage={activePage}
            size={size}
            setActivePage={setActivePage}
          ></Sidebar>
        )}
        {user && user.role ? (
          <TabMenu
            history={history}
            activePage={activePage}
            activeTab={activeTab}
            role={user.role}
            setActiveTab={setActiveTab}
            hide={size.width <= 767 ? true : false}
          />
        ) : null}
        <Switch>
          <Route path="/login">
            {decoded ? (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: "" },
                }}
              />
            ) : (
              <Login />
            )}
          </Route>

          <>
            <PrivateRoute
              exact
              path="/"
              shouldRender={{ state: true, loc: "/" }}
            >
              {user && <ChooseRole user={user} />}
            </PrivateRoute>

            <PrivateRoute
              path="/naslovnica"
              shouldRender={{
                state: activePage.id === 0,
                loc:
                  decoded && user.role === "izvodac"
                    ? "/troskovnici"
                    : "/gradevinski-dnevnik",
              }}
            >
              {decoded && user.role === "izvodac" ? (
                <Naslovnica
                  user={decoded}
                  size={size}
                  handleSetActive={handleSetActive}
                  setAktModal={setAktModal}
                />
              ) : (
                decoded && (
                  <NaslovnicaNadzor
                    user={decoded}
                    size={size}
                    handleSetActive={handleSetActive}
                    setAktModal={setAktModal}
                  />
                )
              )}
            </PrivateRoute>
            <PrivateRoute
              path="/naslovnica-nova"
              shouldRender={{ state: 1, loc: "/" }}
            >
              <NewNaslovnica></NewNaslovnica>
            </PrivateRoute>

            <PrivateRoute
              path="/organizacije"
              shouldRender={{
                state: activePage.id === 0,
                loc: "/troskovnici",
              }}
            >
              {decoded && <Organizacije size={size} user={decoded} />}
            </PrivateRoute>
            <PrivateRoute
              path="/strojevi"
              shouldRender={{
                state: activePage.id === 0,
                loc: "/troskovnici",
              }}
            >
              {decoded && <Strojevi size={size} user={decoded} />}
            </PrivateRoute>
            <PrivateRoute
              path="/zaposlenici"
              shouldRender={{
                state: activePage.id === 0,
                loc: "/troskovnici",
              }}
            >
              {decoded && (
                <Zaposlenici
                  user={decoded}
                  size={size}
                  aktModal={aktModal}
                  setAktModal={setAktModal}
                />
              )}
            </PrivateRoute>
            <PrivateRoute
              path="/aktovi"
              shouldRender={{
                state: activePage.id === 0,
                loc: "/troskovnici",
              }}
            >
              {decoded && (
                <Akt
                  size={size}
                  user={decoded}
                  aktModal={aktModal}
                  setAktModal={setAktModal}
                />
              )}
            </PrivateRoute>
            {activePage && activePage.id ? (
              <>
                <PrivateRoute
                  path="/troskovnici"
                  checkRole={true}
                  shouldRender={{
                    state: activePage.id > 0,
                    loc: "/naslovnica",
                  }}
                >
                  {decoded && (
                    <Troskovnik
                      size={size}
                      user={decoded}
                      activePage={activePage}
                    />
                  )}
                </PrivateRoute>
                <PrivateRoute
                  path="/montazni-dnevnik"
                  checkRole={true}
                  shouldRender={{
                    state: activePage.id > 0,
                    loc: "/naslovnica",
                  }}
                >
                  {decoded && (
                    <MontazniDnevnik
                      size={size}
                      user={decoded}
                      activePage={activePage}
                    />
                  )}
                </PrivateRoute>

                <PrivateRoute
                  path="/gradevinski-dnevnik"
                  shouldRender={{
                    state: activePage.id > 0,
                    loc: "/naslovnica",
                  }}
                >
                  {decoded && user.role === "izvodac" ? (
                    <GradevinskiDnevnik
                      size={size}
                      user={decoded}
                      activePage={activePage}
                    />
                  ) : (
                    decoded && (
                      <GradevinskiDnevnikNadzor
                        size={size}
                        user={decoded}
                        activePage={activePage}
                        role={user.role}
                      />
                    )
                  )}
                </PrivateRoute>
                <PrivateRoute
                  path="/gradevinska-knjiga"
                  checkRole={true}
                  shouldRender={{
                    state: activePage.id > 0,
                    loc: "/naslovnica",
                  }}
                >
                  {decoded && <GradevinskaKnjiga size={size} user={decoded} />}
                </PrivateRoute>
                <PrivateRoute
                  path="/situacija"
                  checkRole={true}
                  shouldRender={{
                    state: activePage.id > 0,
                    loc: "/naslovnica",
                  }}
                >
                  {decoded && <p>coming soon</p>}
                </PrivateRoute>
              </>
            ) : (
              <Redirect
                to={{
                  pathname: "/naslovnica",
                  state: { from: "" },
                }}
              />
            )}
          </>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
