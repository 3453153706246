import React from "react";
import { Modal, Button } from "reactstrap";

const ConfirmModal = ({ warningModal, setWarningModal, action, add }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size={"lg"}
      isOpen={warningModal.state}
      toggle={() =>
        setWarningModal({ ...warningModal, state: !warningModal.state })
      }
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Novi unos
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setWarningModal({ state: false, data: {} })}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <h1>
          Unijeli ste količinu materijala veću nego što je u troškovniku.{" "}
        </h1>
        <Button
          color="primary"
          onClick={() => {
            action(warningModal.data);
            add(warningModal.data);
          }}
        >
          Potvrdi
        </Button>
        <Button onClick={() => setWarningModal({ state: false, data: {} })}>
          Odustani
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
