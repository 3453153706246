import React from "react";
import ReactDatetime from "react-datetime";
import NumberFormat from "react-number-format";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import updateAction from "./updateAction";
import { useStateMachine } from "little-state-machine";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import Select from "react-select";
import { Error } from "../styles";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery } from "react-query";
import CustomDate from "../CustomDate";

const customStyles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      width: "100%",
    };
  },
  control: (styles, { data }) => ({
    ...styles,
    boxShadow:
      " 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important",
    border: "0 !important",
    transition: " box-shadow 0.15s ease !important",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    width: "100%",
  }),
};

const InputForm = ({ user, add }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    action(data);
    add(data);
  };
  const strojeviQuery = useQuery(
    "strojevi",
    async () => {
      const {
        data,
      } = await handlePostRequest("http://building.plc.hr/api/c?m=StrojGets", [
        { user: "igor", orgID: user.orgID },
      ]);
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .map((item) => {
          return {
            ...item,
            label: item.TipStroja,
            value: item.ID,
          };
        })
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        });
      console.log(formatData);
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount >= 3) {
            //handle error
          } else return true;
        }
      },
    }
  );
  const zaposleniciQuery = useQuery(
    "zaposlenici",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "http://building.plc.hr/api/c?m=ZaposlenikGets",
        [{ user: "igor", orgID: user.orgID }]
      );
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .map((item) => {
          return {
            ...item,
            label: item.Struka,
            value: item.ID,
          };
        })
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );

  return (
    <>
      <div className="modal-body">
        <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Struka">Struka</Label>
                  <Controller
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Struka") && "inputError"
                        }`}
                        placeholder="Struka"
                        type="text"
                      />
                    }
                    id="Struka"
                    defaultValue={state.gradevinski.Struka}
                    name="Struka"
                    control={control}
                    rules={{ required: "Struka je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Struka" />
                </Col>
                <Col md="6">
                  <Label for="DatumUpisa">Datum upisa</Label>

                  <Controller
                    id="DatumUpisa"
                    as={<CustomDate autoOk={true} />}
                    name="DatumUpis"
                    control={control}
                    defaultValue={
                      new Date(
                        state.gradevinski.DatumUpis === undefined
                          ? new Date().getTime()
                          : state.gradevinski.DatumUpis
                      )
                    }
                  />

                  <ErrorMessage as={Error} errors={errors} name="DatumUpis" />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Temperatura1">Temperatura 1</Label>
                  <InputGroup className="input-group-alternative ">
                    <Controller
                      id="Temperatura1"
                      as={
                        <NumberFormat
                          decimalSeparator={false}
                          className={`form-control-alternative ${
                            errors.hasOwnProperty("Temperatura1") &&
                            "inputError"
                          }`}
                          placeholder="Temperatura 1"
                          type="text"
                          customInput={Input}
                        ></NumberFormat>
                      }
                      defaultValue={state.gradevinski.Temperatura1}
                      name="Temperatura1"
                      control={control}
                      rules={{ required: "Temperatura 1 je obvezno polje" }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>°</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="Temperatura1"
                  />
                </Col>
                <Col md="6">
                  <Label for="Temperatura2">Temperatura 2</Label>
                  <InputGroup className="input-group-alternative">
                    <Controller
                      id="Temperatura2"
                      as={
                        <NumberFormat
                          decimalSeparator={false}
                          className={`form-control-alternative ${
                            errors.hasOwnProperty("Temperatura2") &&
                            "inputError"
                          }`}
                          placeholder="Temperatura 2"
                          type="text"
                          customInput={Input}
                        ></NumberFormat>
                      }
                      defaultValue={state.gradevinski.Temperatura2}
                      name="Temperatura2"
                      control={control}
                      rules={{ required: "Temperatura 2 je obvezno polje" }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>°</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="Temperatura2"
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Vodostaj">Vodostaj</Label>
                  <InputGroup className="input-group-alternative">
                    <Controller
                      id="Vodostaj"
                      as={
                        <NumberFormat
                          decimalSeparator={false}
                          className={`form-control-alternative ${
                            errors.hasOwnProperty("Vodostaj") && "inputError"
                          }`}
                          placeholder="Vodostaj"
                          type="text"
                          customInput={Input}
                        ></NumberFormat>
                      }
                      defaultValue={state.gradevinski.Vodostaj}
                      name="Vodostaj"
                      control={control}
                      rules={{ required: "Vodostaj je obvezno polje" }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>m</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ErrorMessage as={Error} errors={errors} name="Vodostaj" />
                </Col>
                <Col md="6">
                  <Label for="VremenskiUvjeti">Vremenski uvjeti</Label>
                  <Controller
                    id="VremenskiUvjeti"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("VremenskiUvjeti") &&
                          "inputError"
                        }`}
                        placeholder="Vremenski uvjeti"
                        type="text"
                      />
                    }
                    defaultValue={state.gradevinski.VremenskiUvjeti}
                    name="VremenskiUvjeti"
                    control={control}
                    rules={{ required: "Vremenski uvjeti je obvezno polje" }}
                  />
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="VremenskiUvjeti"
                  />
                </Col>
              </Row>
            </FormGroup>

            <FormGroup>
              <Row>
                <Col md="6">
                  <Label for="Zaposlenici">Zaposlenici</Label>
                  <Controller
                    id="Zaposlenici"
                    as={<Select styles={customStyles} />}
                    defaultValue={state.gradevinski.Zaposlenici}
                    isMulti
                    placeholder="Odaberite zaposlenike"
                    name="Zaposlenici"
                    onChange={(value) => {
                      if (value[1].action === "clear") {
                        return null;
                      } else return value[0];
                    }}
                    control={control}
                    rules={{
                      required: "Zaposlenici je obvezno polje",
                      min: { value: 1, message: "Error" },
                    }}
                    options={zaposleniciQuery.data}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Zaposlenici" />
                </Col>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Strojevi">Strojevi</Label>
                  <Controller
                    id="Strojevi"
                    as={<Select styles={customStyles} />}
                    defaultValue={state.gradevinski.Strojevi}
                    isMulti
                    placeholder="Odaberite strojeve"
                    onChange={(value) => {
                      if (value[1].action === "clear") {
                        return null;
                      } else return value[0];
                    }}
                    name="Strojevi"
                    control={control}
                    rules={{ required: "Strojevi je obvezno polje" }}
                    options={strojeviQuery.data}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Strojevi" />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6">
                  <Label for="UpisVoditelja">Upis voditelja</Label>
                  <Controller
                    id="UpisVoditelja"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("UpisVoditelja") && "inputError"
                        }`}
                        placeholder="Upis voditelja"
                        type="text"
                      />
                    }
                    defaultValue={state.gradevinski.UpisVoditelja}
                    name="UpisVoditelja"
                    control={control}
                    rules={{ required: "Upis voditelja je obvezno polje" }}
                  />
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="UpisVoditelja"
                  />
                </Col>
                <Col md="6">
                  <Label for="UpisNadzora">Upis nadzora</Label>
                  <Controller
                    id="UpisNadzora"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("UpisNadzora") && "inputError"
                        }`}
                        placeholder="Upis nadzora"
                        type="text"
                      />
                    }
                    defaultValue={state.gradevinski.UpisNadzora}
                    name="UpisNadzora"
                    control={control}
                    rules={{ required: "Upis nadzora je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="UpisNadzora" />
                </Col>
              </Row>
            </FormGroup>
            <Row style={{ marginTop: 20 }} className="justify-content-center">
              <Col md="2">
                <Button color="primary" type="submit">
                  Potvrdi
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <div className="modal-footer"></div>
    </>
  );
};

export default InputForm;
