import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import NumberFormat from "react-number-format";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { Error } from "../../styles";

const Cijena = ({ handleNext, user, handleBack }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors, getValues } = useForm({
    defaultValues: {
      Cijena: state.data.Cijena,
      ugovor: state.data.ugovor,
    },
  });

  const submitBack = () => {
    action(getValues());
    handleBack();
  };

  const onSubmit = (data) => {
    action(data);
    handleNext();
  };

  return (
    <>
      <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="6">
              <Label for="Naziv">Naziv</Label>
              <Controller
                id="Naziv"
                as={
                  <Input
                    className={`form-control-alternative ${
                      errors.hasOwnProperty("Naziv") && "inputError"
                    }`}
                    placeholder="Naziv"
                    type="text"
                  />
                }
                name="Naziv"
                defaultValue={state.data.Naziv}
                control={control}
                rules={{ required: "Naziv je obvezno polje" }}
              />
              <ErrorMessage as={Error} errors={errors} name="Naziv" />
            </Col>
            <Col md="6">
              <Label for="Cijena">Cijena</Label>
              <InputGroup className="input-group-alternative mb-4">
                <Controller
                  id="Cijena"
                  as={
                    <NumberFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      className={`form-control-alternative ${
                        errors.hasOwnProperty("Cijena") && "inputError"
                      }`}
                      placeholder="Cijena"
                      customInput={Input}
                    ></NumberFormat>
                  }
                  name="Cijena"
                  control={control}
                  rules={{
                    required: "Cijena je obvezno polje",
                    min: { value: 1, message: "Minimalan iznos je 1" },
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>HRK</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
              <ErrorMessage as={Error} errors={errors} name="Cijena" />
            </Col>
          </Row>
          <Row className="justify-content-center"></Row>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <Button onClick={submitBack}>Natrag</Button>
              <Button color="primary" type="submit">
                Sljedeći
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default Cijena;
