import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { Button, Form, Input, Card, Row, Col, Label } from "reactstrap";
import { Error } from "../../styles";

const InvestitorIzvodac = ({ handleNext }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      Izvodac: state.data.Izvodac,
      Investitor: state.data.Investitor,
    },
  });

  const onSubmit = (data) => {
    action(data);
    handleNext();
  };

  return (
    <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col md="6">
            <Label for="Izvodac">Izvođač</Label>
            <Controller
              id="Izvodac"
              as={
                <Input
                  className={`form-control-alternative ${
                    errors.hasOwnProperty("Izvodac") && "inputError"
                  }`}
                  placeholder="Izvođač"
                  type="text"
                />
              }
              name="Izvodac"
              control={control}
              rules={{ required: "Izvođač je obvezno polje" }}
            />
            <ErrorMessage as={Error} errors={errors} name="Izvodac" />
          </Col>
          <Col md="6">
            <Label for="Investitor">Investitor</Label>
            <Controller
              id="Investitor"
              as={
                <Input
                  className={`form-control-alternative is-invalid ${
                    errors.hasOwnProperty("Investitor") && "inputError"
                  }`}
                  placeholder="Investitor"
                  type="text"
                />
              }
              name="Investitor"
              control={control}
              rules={{ required: "Investitor je obvezno polje" }}
            />
            <ErrorMessage as={Error} errors={errors} name="Investitor" />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col>
            <Button color="primary" type="submit">
              Sljedeći
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default InvestitorIzvodac;
