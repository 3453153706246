import React from "react";
import { Button } from "reactstrap";
import { StateMachineProvider } from "little-state-machine";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import InvestitorIzvodac from "./Form/InvestitorIzvodac";
import Cijena from "./Form/Cijena";
import Gradevina from "./Form/Gradevina";
import Nadzor from "./Form/Nadzor";
import Dates from "./Form/Dates";
import LastStep from "./LastStep";
import { useQuery } from "react-query";
import { handlePostRequest } from "../../helpers/handle-response";
import "./Form.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    textAlign: "center",
  },
}));

function getSteps() {
  return [
    "Investitor i izvođač",
    "Naziv i cijena",
    "Građevina",
    "Stručni nadzor",
    "Datumi",
  ];
}

function getStepContent(
  step,
  handleNext,
  handleBack,
  setAktModal,
  data,
  add,
  user,
  size
) {
  switch (step) {
    case 0:
      return <InvestitorIzvodac handleNext={handleNext} />;
    case 1:
      return (
        <Cijena
          handleNext={handleNext}
          handleBack={handleBack}
          setAktModal={setAktModal}
          user={user}
        />
      );
    case 2:
      return (
        <Gradevina
          handleNext={handleNext}
          handleBack={handleBack}
          setAktModal={setAktModal}
          zaposlenici={data}
          user={user}
          size={size}
        />
      );
    case 3:
      return (
        <Nadzor
          handleNext={handleNext}
          handleBack={handleBack}
          setAktModal={setAktModal}
          zaposlenici={data}
          user={user}
          size={size}
        />
      );
    case 4:
      return (
        <Dates
          handleNext={handleNext}
          handleBack={handleBack}
          add={add}
          user={user}
        />
      );
    default:
      return "Unknown step";
  }
}

export default function FormStepper({
  setOpen,
  setAktModal,
  user,
  add,
  addStatus,
  isSuccess,
  size,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const { status, data, error, isFetching } = useQuery(
    "zaposlenik",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=ZaposlenikGets",
        [{ user: "igor", orgID: user.orgID }]
      );
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        })
        .map((item) => {
          return {
            ...item,
            value: item.ID,
            label: item.Ime + " " + item.Prezime,
          };
        });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className={classes.root}>
      <StateMachineProvider>
        <Stepper
          activeStep={activeStep}
          orientation={size.width < 1024 ? "vertical" : "horizontal"}
        >
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <LastStep
              setOpen={setOpen}
              addStatus={addStatus}
              isSuccess={isSuccess}
            />
          ) : (
            <div>
              {getStepContent(
                activeStep,
                handleNext,
                handleBack,
                setAktModal,
                data,
                add,
                user,
                size
              )}
              {/*  <div style={{ marginTop: 20 }}>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                className={classes.button}
              >
                Natrag
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Završi" : "Sljedeći"}
              </Button>
            </div> */}
            </div>
          )}
        </div>
      </StateMachineProvider>
    </div>
  );
}
