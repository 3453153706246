import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import NumberFormat from "react-number-format";
import updateAction from "./updateAction";
import { useStateMachine } from "little-state-machine";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { Error } from "../styles";

const formatCurrency = (value) =>
  new Intl.NumberFormat("hr-HR", {}).format(value);

const InputForm = ({ add, reqError }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors, setValue, getValues } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    action(data);
    add(data);
  };
  return (
    <>
      <div className="modal-body">
        <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Naziv">Naziv</Label>
                  <Controller
                    id="Naziv"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Naziv") && "inputError"
                        }`}
                        placeholder="Naziv"
                        type="text"
                      />
                    }
                    defaultValue={state.organizacija.Naziv}
                    name="Naziv"
                    control={control}
                    rules={{ required: "Naziv je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Naziv" />
                </Col>
                <Col md="6">
                  <Label for="OIB">OIB</Label>
                  <Controller
                    id="OIB"
                    as={
                      <NumberFormat
                        value={232}
                        allowLeadingZeros
                        decimalSeparator={false}
                        autoComplete={false}
                        maxLength={11}
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("OIB") && "inputError"
                        }`}
                        placeholder="OIB"
                        type="text"
                        customInput={Input}
                      ></NumberFormat>
                    }
                    defaultValue={state.organizacija.OIB}
                    name="OIB"
                    control={control}
                    rules={{
                      required: "OIB je obvezno polje",
                      minLength: {
                        value: 11,
                        message: "OIB mora sadržavati najmanje 11 znakova",
                      },
                    }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="OIB" />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="IBAN">IBAN</Label>
                  <Controller
                    id="IBAN"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("IBAN") && "inputError"
                        }`}
                        placeholder="IBAN"
                        type="text"
                        maxLength={21}
                      />
                    }
                    defaultValue={state.organizacija.IBAN}
                    name="IBAN"
                    control={control}
                    rules={{
                      required: "IBAN je obvezno polje",
                      minLength: {
                        value: 21,
                        message: "IBAN mora imati najmanje 21 znak",
                      },
                    }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="IBAN" />
                </Col>
                <Col md="6">
                  <Label for="OdgovornaOsoba">Odgovorna osoba</Label>
                  <Controller
                    id="OdgovornaOsoba"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("OdgovornaOsoba") &&
                          "inputError"
                        }`}
                        placeholder="Odgovorna osoba"
                        type="text"
                      />
                    }
                    defaultValue={state.organizacija.OdgovornaOsoba}
                    name="OdgovornaOsoba"
                    control={control}
                    rules={{
                      required: "Odgovorna osoba je obvezno polje",
                    }}
                  />
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="OdgovornaOsoba"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Struka">Struka</Label>
                  <Controller
                    id="Struka"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Struka") && "inputError"
                        }`}
                        placeholder="Struka"
                        type="text"
                      />
                    }
                    defaultValue={state.organizacija.Struka}
                    name="Struka"
                    control={control}
                    rules={{ required: "Struka je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Struka" />
                </Col>
                <Col md="6">
                  <Label for="Adresa">Adresa</Label>
                  <Controller
                    id="Adresa"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Adresa") && "inputError"
                        }`}
                        placeholder="Adresa"
                        type="text"
                      />
                    }
                    defaultValue={state.organizacija.Adresa}
                    name="Adresa"
                    control={control}
                    rules={{ required: "Adresa je obvezno polje" }}
                  />

                  <ErrorMessage as={Error} errors={errors} name="Adresa" />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Mjesto">Mjesto</Label>
                  <Controller
                    id="Mjesto"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Mjesto") && "inputError"
                        }`}
                        placeholder="Mjesto"
                        type="text"
                      />
                    }
                    defaultValue={state.organizacija.Mjesto}
                    name="Mjesto"
                    control={control}
                    rules={{ required: "Mjesto je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Mjesto" />
                </Col>
                <Col md="6">
                  <Label for="Drzava">Država</Label>
                  <Controller
                    id="Drzava"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Drzava") && "inputError"
                        }`}
                        placeholder="Država"
                        type="text"
                      />
                    }
                    defaultValue={state.organizacija.Drzava}
                    name="Drzava"
                    control={control}
                    rules={{ required: "Država je obvezno polje" }}
                  />

                  <ErrorMessage as={Error} errors={errors} name="Drzava" />
                </Col>
              </Row>
            </FormGroup>

            <Row className="justify-content-center">
              <Col md="6">
                <Label for="Email">Email</Label>
                <Controller
                  id="Email"
                  as={
                    <Input
                      className={`form-control-alternative ${
                        errors.hasOwnProperty("Email") && "inputError"
                      }`}
                      placeholder="Email"
                      type="text"
                    />
                  }
                  defaultValue={state.organizacija.Email}
                  name="Email"
                  control={control}
                  rules={{
                    required: "Email je obvezno polje",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email adresa nije ispravna",
                    },
                  }}
                />

                <ErrorMessage as={Error} errors={errors} name="Email" />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }} className="justify-content-center">
              <Col md="2">
                <Button color="primary" type="submit">
                  Potvrdi
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                {reqError.message.length !== 0 && (
                  <h4 className="error">{reqError.message}</h4>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <div className="modal-footer"></div>
    </>
  );
};

export default InputForm;
