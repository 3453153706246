/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { authenticationService } from "../../services/authentication";
import "./Sidebar.scss";
// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import ProfileMenu from "../App/ProfileMenu";
function Sidebar({ user, activePage, setActivePage, size }, props) {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  let history = useHistory();
  // toggles collapse between opened and closed (true/false)

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            onClick={() => {
              console.log("click render");
              prop.action();
              setCollapseOpen(!collapseOpen);
            }}
            style={{ cursor: "pointer" }}
          >
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  const firstRoutes = [
    {
      name: "Naslovnica",
      action: () => {
        history.push("/");
      },
    },
    {
      name: "Organizacije",
      action: () => {
        history.push("/organizacije");
      },
    },
    {
      name: "Zaposlenici",
      action: () => {
        history.push("/zaposlenici");
      },
    },
    {
      name: "Strojevi",
      action: () => {
        history.push("/strojevi");
      },
    },
    {
      name: "Aktovi",
      action: () => {
        history.push("/aktovi");
      },
    },
  ];
  const secondRoutes = [
    {
      name: "Troškovnik",
      action: () => {
        history.push("/troskovnici");
      },
    },
    {
      name: "Montažni dnevnik",
      action: () => {
        history.push("/montazni-dnevnik");
      },
    },
    {
      name: "Građevinski dnevnik",
      action: () => {
        history.push("/gradevinski-dnevnik");
      },
    },
    {
      name: "Građevinska knjiga",
      action: () => {
        history.push("/gradevinska-knjiga");
      },
    },
    {
      name: "Situacija",
      action: () => {
        history.push("/situacija");
      },
    },
  ];
  const routes = activePage.name.length === 0 ? firstRoutes : secondRoutes;

  const logout = [
    {
      name: "Odjava",
      action: () => {
        authenticationService.logout();
      },
    },
  ];
  const handleRole = [
    {
      name: "Promjeni ulogu",
      action: () => {
        const obj = { ...user, role: undefined };
        authenticationService.updateUser(obj);
        authenticationService.setNaslovnica({
          name: "",
          id: 0,
        });
        history.push("/");
      },
    },
  ];
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white navbarCustom"
      expand="md"
      id="sidenav-main"
      {...props}
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row className="justify-content-center">
              <Col className="text-center" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={() => setCollapseOpen(!collapseOpen)}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          {/* Navigation */}
          {user ? (
            <>
              <Nav navbar>{createLinks(routes)}</Nav>
              <hr className="my-3" />
            </>
          ) : null}
          {/* Divider */}

          <Nav navbar>{createLinks(handleRole)} </Nav>
          <Nav navbar>{createLinks(logout)} </Nav>
        </Collapse>
        {activePage.name.length !== 0 ? (
          <Button
            size="sm"
            color="default"
            style={
              size.width >= 767 ? { display: "none" } : { marginLeft: "auto" }
            }
            onClick={() => {
              setActivePage({ name: "", id: 0 });
              authenticationService.setNaslovnica({ name: "", id: 0 });
              history.push("/");
            }}
          >
            Izađi iz naslovnice
          </Button>
        ) : null}
        {/*  <ProfileMenu
          isMobile={size.width <= 767 ? true : false}
          marginLeft={
            activePage.name.length === 0 || size.width <= 767 ? true : false
          }
          color="#172B4D"
        /> */}
      </Container>
    </Navbar>
  );
}

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
