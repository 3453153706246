import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import NumberFormat from "react-number-format";
import updateAction from "./updateAction";
import updateAkt from "../Akt/updateAction";
import { useStateMachine } from "little-state-machine";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import { useQuery, useMutation, queryCache } from "react-query";
import { handlePostRequest } from "../../helpers/handle-response";
import { Error } from "../styles";
import Imenovanja from "../Akt/Imenovanja";
//import { ReactQueryDevtools } from "react-query-devtools";

const customStyles = {
  control: (styles, { data }) => ({
    ...styles,
    boxShadow:
      " 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important",
    border: "0 !important",
    transition: " box-shadow 0.15s ease !important",
  }),
};
const InputForm = ({ aktToAdd, from, addZaposlenik, user, size }) => {
  const { action, state } = useStateMachine(updateAction);
  const { action: aktAction } = useStateMachine(updateAkt);
  const { control, handleSubmit, errors, setValue } = useForm();
  const [aktModal, setAktModal] = React.useState({ from: "", open: false });
  const [reqError, setReqError] = React.useState({});
  const [addedAkt, setAddedAkt] = React.useState({ refresh: "" });

  const onSubmit = (data) => {
    if (from === "edit") {
      console.log("send to edit");
    } else {
      console.log("send to add");
    }
    console.log({ ...state.zaposlenik, ...data });
    action(data);
    addZaposlenik({ ...state.zaposlenik, ...data });
  };

  const setAkt = (data) => {
    //Find akt first
    console.log(addedAkt);
    const obj = data.find((item) => {
      if (item.naziv === addedAkt.naziv) {
        return item;
      }
    });
    console.log(obj);
    setValue(aktModal.from, { ...obj, label: obj.naziv, value: obj.ID });
  };
  const { status, data, error, isFetching, isLoading, refetch } = useQuery(
    "aktovi",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=AktoviGets",
        [{ user: "igor", orgID: user.orgID }]
      );
      const parsedData = JSON.parse(data);

      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        })
        .map((item) => {
          return {
            ...item,
            value: item.ID,
            label: item.naziv,
          };
        });
      console.log(formatData);
      if (addedAkt.refresh === "refresh") {
        setAkt(formatData);
        setAddedAkt({ refresh: "" });
      }
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );

  const [addAkt] = useMutation(
    async (dataToAdd) => {
      const a = {
        ID: 0,
        OrganizationID: user.orgID,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
        ...dataToAdd,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=AktoviAddEdit",
        [{ user: "igor", a }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("aktovi");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("aktovi");
          if (aktModal.open && !isFetching) {
            setAktModal({ ...aktModal, open: false });
          }
        }
      },
    }
  );
  return (
    <>
      <div className="modal-body">
        <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Ime">Ime</Label>
                  <Controller
                    id="Ime"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Ime") && "inputError"
                        }`}
                        placeholder="Ime"
                        type="text"
                      />
                    }
                    defaultValue={state.zaposlenik.Ime}
                    name="Ime"
                    control={control}
                    rules={{ required: "Ime je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Ime" />
                </Col>
                <Col md="6">
                  <Label for="Prezime">Prezime</Label>
                  <Controller
                    id="Prezime"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Prezime") && "inputError"
                        }`}
                        placeholder="Prezime"
                        type="text"
                      />
                    }
                    defaultValue={state.zaposlenik.Prezime}
                    name="Prezime"
                    control={control}
                    rules={{ required: "Prezime je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Prezime" />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="OIB">OIB</Label>
                  <Controller
                    id="OIB"
                    as={
                      <NumberFormat
                        value={232}
                        allowLeadingZeros
                        decimalSeparator={false}
                        autoComplete={false}
                        maxLength={11}
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("OIB") && "inputError"
                        }`}
                        placeholder="OIB"
                        type="text"
                        customInput={Input}
                      ></NumberFormat>
                    }
                    defaultValue={state.zaposlenik.OIB}
                    name="OIB"
                    control={control}
                    rules={{
                      required: "OIB je obvezno polje",
                      minLength: {
                        value: 11,
                        message: "OIB mora sadržavati najmanje 11 znakova",
                      },
                    }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="OIB" />
                </Col>
                <Col md="6">
                  <Label for="Struka">Struka</Label>
                  <Controller
                    id="Struka"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Struka") && "inputError"
                        }`}
                        placeholder="Struka"
                        type="text"
                      />
                    }
                    defaultValue={state.zaposlenik.Struka}
                    name="Struka"
                    control={control}
                    rules={{
                      required: "Struka je obvezno polje",
                    }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Struka" />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="RazinaObrazovanja">Razina obrazovanja</Label>
                  <Controller
                    id="RazinaObrazovanja"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("RazinaObrazovanja") &&
                          "inputError"
                        }`}
                        placeholder="Razina obrazovanja"
                        type="text"
                      />
                    }
                    defaultValue={state.zaposlenik.RazinaObrazovanja}
                    name="RazinaObrazovanja"
                    control={control}
                    rules={{ required: "Razina obrazovanja je obvezno polje" }}
                  />
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="RazinaObrazovanja"
                  />
                </Col>
                <Col md="6">
                  <Label for="StrucnaTitula">Stručna titula</Label>
                  <Controller
                    id="StrucnaTitula"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("StrucnaTitula") && "inputError"
                        }`}
                        placeholder="Stručna titula"
                        type="text"
                      />
                    }
                    defaultValue={state.zaposlenik.StrucnaTitula}
                    name="StrucnaTitula"
                    control={control}
                    rules={{ required: "Stručna titula je obvezno polje" }}
                  />

                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="StrucnaTitula"
                  />
                </Col>
              </Row>
            </FormGroup>

            <Row className="justify-content-center">
              <Col md="6">
                <Label for="ZnanstvenaTitula">Znanstvena titula</Label>
                <Controller
                  id="ZnanstvenaTitula"
                  as={
                    <Input
                      className={`form-control-alternative ${
                        errors.hasOwnProperty("ZnanstvenaTitula") &&
                        "inputError"
                      }`}
                      placeholder="Znanstvena titula"
                      type="text"
                    />
                  }
                  defaultValue={state.zaposlenik.ZnanstvenaTitula}
                  name="ZnanstvenaTitula"
                  control={control}
                  rules={{ required: "Znanstvena titula je obvezno polje" }}
                />
                <ErrorMessage
                  as={Error}
                  errors={errors}
                  name="ZnanstvenaTitula"
                />
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="5" className="mb-2 mb-md-0">
                <Label for="ovg">Akt ovg</Label>
                <Controller
                  id="ovg"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.zaposlenik.ovg}
                  placeholder="Odaberite akt ovg"
                  name="ovg"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else return value[0];
                  }}
                  control={control}
                  rules={{
                    required: "Akt ovg je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={data}
                />
                <ErrorMessage as={Error} errors={errors} name="ovg" />
              </Col>
              <Col
                md="1"
                style={
                  size.width < 1024
                    ? {}
                    : errors.hasOwnProperty("ovg")
                    ? { marginTop: "6vh" }
                    : { marginTop: "6vh" }
                }
              >
                <Button
                  size="sm"
                  title="Dodaj novi akt"
                  className="mx-auto d-flex"
                  type="button"
                  onClick={() => {
                    aktAction({
                      Klasa: "",
                      UrBroj: "",
                      mjesto: "",
                      nadnevak: new Date(),
                      naziv: "",
                    });
                    setAktModal({ from: "ovg", open: true });
                  }}
                >
                  +
                </Button>
              </Col>
              <Col md="5" className="mb-2 mb-md-0">
                <Label for="oib">Akt oie</Label>
                <Controller
                  id="oie"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.zaposlenik.oie}
                  placeholder="Odaberite akt oie"
                  name="oie"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else return value[0];
                  }}
                  control={control}
                  rules={{
                    required: "Akt oie je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={data}
                />
                <ErrorMessage as={Error} errors={errors} name="oie" />
              </Col>
              <Col
                md="1"
                style={
                  size.width < 1024
                    ? {}
                    : errors.hasOwnProperty("oie")
                    ? { marginTop: "6vh" }
                    : { marginTop: "6vh" }
                }
              >
                <Button
                  size="sm"
                  title="Dodaj novi akt"
                  type="button"
                  className="mx-auto d-flex"
                  onClick={() => {
                    aktAction({
                      Klasa: "",
                      UrBroj: "",
                      mjesto: "",
                      nadnevak: new Date(),
                      naziv: "",
                    });
                    setAktModal({ from: "oie", open: true });
                  }}
                >
                  +
                </Button>
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }} className="justify-content-center">
              <Col md="2">
                <Button color="primary" type="submit">
                  Potvrdi
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
        <Imenovanja
          aktovi={data}
          aktModal={aktModal}
          setAktModal={setAktModal}
          add={addAkt}
          setAkt={setAddedAkt}
        />
      </div>
      <div className="modal-footer"></div>
    </>
  );
};

export default InputForm;
