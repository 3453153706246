import React from "react";
import { Modal, Button } from "reactstrap";
import { formatDateToString } from "../../utils/functions";

const DeleteModal = ({ open, setOpen, rowToDelete, delFunc }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size={"lg"}
      isOpen={open.Delete}
      toggle={() => setOpen({ ...open, Delete: !open.Delete })}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Obriši unos
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen({ ...open, Delete: !open.Delete })}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="mx-auto">
        <h2 className="ml-4 ml-sm-0">Jeste li sigurni da želite obrisati unos u dnevniku?</h2>
        <ul>
          <li>
            Početak rada:{" "}
            <span className="font-weight-bold">
              {" "}
              {formatDateToString(rowToDelete.PocetakRada)}
            </span>
          </li>
          <li>
            Završetak rada:{" "}
            <span className="font-weight-bold">
              {" "}
              {formatDateToString(rowToDelete.ZavrsetakRada)}
            </span>
          </li>
          <li>
            Zaposlenici:{" "}
            <span className="font-weight-bold">{rowToDelete.Zaposlenici}</span>
          </li>
          <li>
            Strojevi:{" "}
            <span className="font-weight-bold">{rowToDelete.Strojevi}</span>
          </li>
          <li>
            UpisPoslovode:{" "}
            <span className="font-weight-bold">
              {rowToDelete.UpisPoslovode}
            </span>
          </li>
          <li>
            UpisNadzora:{" "}
            <span className="font-weight-bold"> {rowToDelete.UpisNadzora}</span>
          </li>
          <li>
            UtrosakMaterijala:{" "}
            <span className="font-weight-bold">
              {" "}
              {rowToDelete.UtrosakMaterijala}
            </span>
          </li>
        </ul>
        <Button
          className="mx-auto my-2 d-flex"
          color="danger"
          onClick={delFunc}
        >
          Obriši
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
