import React from "react";
import { authenticationService } from "../../services/authentication";
import {
  formatDateToString,
  fillArray,
  b64toBlob,
} from "../../utils/functions";
import DeleteModal from "./DeleteModal";
import { Button } from "reactstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
import style from "./GradevinskiDnevnik.module.scss";
import Table from "../Table/Table";
import FormModal from "./FormModal";
import { useStateMachine } from "little-state-machine";
import nadzorUpdateAction from "./nadzorUpdateAction";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";
import { saveAs } from "file-saver";

const GradevinskiDnevnikNadzor = ({ user, activePage, role }) => {
  const [rowToEdit, setRowToEdit] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [modals, setModals] = React.useState({
    Delete: false,
  });
  const { action, state } = useStateMachine(nadzorUpdateAction);

  const [open, setOpen] = React.useState({ state: false, from: "" });
  const [reqError, setReqError] = React.useState({ state: false, message: "" });
  const [downloading, setDownloading] = React.useState(false);

  const { status, data, error, isLoading, isFetching, refetch } = useQuery(
    "nadzorDnevnik",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=NadzorGets",
        [{ user: "igor", naslovnicaID: activePage.id }]
      );
      const parsedData = JSON.parse(data);
      const formatData = parsedData.filter((item) => {
        if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
          return true;
        } else return null;
      });
      console.log(formatData);
      const tableData = formatData.map((item) => {
        const strojevi = item.Strojevi;
        const zaposlenici = item.Zaposlenici;
        const arr = [];
        const arrZap = [];
        for (let key in strojevi) {
          arr.push({ [key]: strojevi[key], label: key, value: strojevi[key] });
        }
        for (let key in zaposlenici) {
          arrZap.push({
            [key]: zaposlenici[key],
            label: key,
            value: zaposlenici[key],
          });
        }
        const objStr = {
          Strojevi: arr
            .map((item) => {
              return fillArray(item.label, item.value);
            })
            .flat(),
        };
        const objZap = {
          Zaposlenici: arrZap
            .map((item) => {
              return fillArray(item.label, item.value);
            })
            .flat(),
        };
        return { ...item, ...objStr, ...objZap };
      });
      console.log(tableData);
      return tableData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount >= 3) {
            authenticationService.logout();
          } else return true;
        }
      },
    }
  );

  const [addDnevnik] = useMutation(
    async (dataToAdd) => {
      const ni = {
        ...dataToAdd,
        ID: open.from === "edit" ? rowToEdit : 0,
        NaslovnicaID: activePage.id,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=NadzorAddEdit",
        [{ user: "igor", ni, ip: "" }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("nadzorDnevnik");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("nadzorDnevnik");
          if (open.state && !isFetching) {
            setOpen({ ...open, state: false });
          }
        }
      },
    }
  );
  const [deleteDnevnik] = useMutation(
    async () => {
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=NadzorDelete",
        [{ user: "igor", ID: rowToDelete.ID }]
      );
      return res;
    },
    {
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("nadzorDnevnik");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("nadzorDnevnik");
          if (modals.Delete && !isFetching) {
            setModals({ ...modals, Delete: false });
          }
        }
      },
    }
  );
  const cols = React.useMemo(
    () => [
      {
        accessor: "DatumUpis",
        className: "alignCenter",
        Header: "Datum upisa",
        Cell: (props) => formatDateToString(props.cell.value),
      },
      {
        accessor: "Opis",
        Header: "Opis",
        className: "alignCenter",
      },

      {
        accessor: "Uredi",
        Header: "Uredi",
        className: "alignCenter",
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.editButton}
                type="button"
                onClick={(e) => {
                  setRowToEdit(props.row.original.ID);
                  action(props.row.original);
                  console.log(props.row.original);
                  setOpen({ state: true, from: "edit" });
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            );
          return null;
        },
      },
      {
        accessor: "Obrisi",
        Header: "Obriši",
        disableSortBy: true,
        className: "alignCenter",
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.deleteButton}
                type="button"
                onClick={() => {
                  setModals({ ...modals, Delete: true });
                  setRowToDelete(props.row.original);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            );
          return null;
        },
      },
    ],
    [modals, action]
  );
  const generatePDF = async () => {
    setDownloading(true);
    const res = await handlePostRequest(
      "https://www.pdf-api.accountant.hr/get-pdf",
      [{ data, name: "nadzorDnevnik" }]
    )
      .then((res) => {
        const url = window.URL.createObjectURL(
          b64toBlob(res.data, "application/pdf")
        );
        saveAs(url, `NadzorDnevnik.pdf`);
        setDownloading(false);
        //setIsDownloading(false);
      })
      .catch((err) => console.log(err));
    console.log(res);
  };
  return (
    <>
      {status === "loading" || status === "error" ? (
        <div className="d-flex mx-auto justify-content-center mt-4">
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : (
        <div>
          <div className="main-content">
            {downloading ? (
              <div className="d-flex justify-content-start mt-1 ml-4 px-2 py-3">
                <CircularProgress size="2rem" color="primary" />
              </div>
            ) : (
              <section className={style.buttons}>
                <Button
                  color="primary"
                  onClick={() => {
                    action({
                      DatumUpis: new Date(),
                      Strojevi: null,
                      Struka: "",
                      Temperatura1: "",
                      Temperatura2: "",
                      UpisNadzora: "",
                      UpisVoditelja: "",
                      Vodostaj: "",
                      VremenskiUvjeti: "",
                      Zaposlenici: null,
                      Opis: "",
                    });
                    setOpen({ from: "add", state: !open.state });
                  }}
                >
                  Novi unos
                </Button>

                <Button color="primary" onClick={generatePDF}>
                  PDF
                </Button>
              </section>
            )}

            <Table
              columns={cols}
              initialSort={[{ id: "Naziv", desc: false }]}
              data={data}
            ></Table>
          </div>
        </div>
      )}
      <FormModal
        open={open}
        user={user}
        setOpen={setOpen}
        add={addDnevnik}
        reqError={reqError}
        role={role}
      />
      {modals.Delete ? (
        <DeleteModal
          open={modals}
          setOpen={setModals}
          rowToDelete={rowToDelete}
          delFunc={deleteDnevnik}
        ></DeleteModal>
      ) : null}
    </>
  );
};

export default GradevinskiDnevnikNadzor;
