import { authenticationService } from "../services/authentication";
import axios from "axios";

export function handleResponse(response) {
  return response.json().then((json) => {
    const data = json;
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        authenticationService.logout();
        window.location.reload(true);
      }

      const error = (data && data.error) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}

const instance = axios.create();
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    console.log("handle function log", response);
    if (
      response.data === "Error: User not loaded, please load and try again." ||
      //REMOVED BECAUSE SOMETIMES TABLE IS EMPTY => response.data === "{}" ||
      response.data.includes("There is no row at position -1")
    ) {
      //authenticationService.logout();
      return Promise.reject("Retry");
    }
    if (
      response.data ===
      "Critical error in PBI_Main.Load : An item with the same key has already been added."
    ) {
      instance
        .post("http://building.plc.hr/api/c?m=Unload", { user: "igor" })
        .then((res) => {
          authenticationService.logout();
          window.location.reload(true);
        });
    } else if (response.data === "False") {
      response.status = 500;
      response.statusText = "Error";
      return response;
    } else return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    //replace with currentUser.username later
    console.log(error);
    /*  instance
      .post(
        "http://139.59.206.191:8080/http://accountant.plc.hr/Place/Unload",
        { user: "igor" }
      )
      .then(res => {
        authenticationService.logout();
        window.location.reload(true);
      }); */
    authenticationService.logout();
    window.location.reload(true);
    return Promise.reject(error);
  }
);

export function handlePostRequest(url, options, token) {
  //console.log(url, options, token);

  return instance.post(url, ...options, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}
export function handleFileUpload(url, Files, token) {
  return instance.post(url, Files, {
    headers: {
      Authorization: `JWT ${token}`,
    },
  });
}
export function handleGetRequest(url, token, type) {
  return instance.get(url, {
    headers: { Authorization: `JWT ${token}` },
    responseType: type,
  });
}
