import React from "react";
import { useForm, Controller } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { Button, Form, Label, Card, Row, Col, FormGroup } from "reactstrap";
import * as yup from "yup";
import CustomDate from "../../CustomDate";

const DateSchema = yup.object().shape({
  PocetakPrijave: yup
    .date()
    .typeError("Nadnevak mora biti datum")
    .required("Nadnevak je obvezno polje"),
  Pocetak: yup
    .date()
    .typeError("Nadnevak mora biti datum")
    .required("Nadnevak je obvezno polje"),
  /* Zavrsetak: yup
    .date()
    .typeError("Nadnevak mora biti datum")
    .required("Nadnevak je obvezno polje"), */
});

const Dates = ({ handleNext, handleBack, add, user }) => {
  const { control, handleSubmit } = useForm({ validationSchema: DateSchema });
  const { action, state } = useStateMachine(updateAction);
  const [coords, setCoords] = React.useState({});

  React.useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      setCoords({
        LongitudeDuzinaIZ: pos.coords.longitude,
        LatitudeSirinaSJ: pos.coords.latitude,
      });
    });
  });
  const onSubmit = (data) => {
    action(data);
    //send state to db here

    const dataToSend = { ...state.data, ...data };
    const formatedData = {
      nadzor1: {
        imenovanje: dataToSend.Akt1,
        osoba: dataToSend.Nadzor1,
      },
      nadzor2: {
        imenovanje: dataToSend.Akt2,
        osoba: dataToSend.Nadzor2,
      },
      nadzor3: {
        imenovanje: dataToSend.Akt3,
        osoba: dataToSend.Nadzor3,
      },
      glavniNadzor: {
        imenovanje: dataToSend.AktGlavni,
        osoba: dataToSend.GlavniNadzor,
      },
      voditelj: {
        imenovanje: dataToSend.AktImenovanje,
        osoba: dataToSend.GlavniInzenjer,
      },
      Naziv: dataToSend.Naziv,
      Izvodac: dataToSend.Izvodac,
      ugovor: dataToSend.ugovor,
      CijenaBezPdv:
        typeof dataToSend.Cijena === typeof ""
          ? Number(dataToSend.Cijena.replace(".", "").replace(",", "."))
          : dataToSend.Cijena,
      Investitor: dataToSend.Investitor,
      Gradevina: dataToSend.Gradevina,
      Lokacija: dataToSend.Lokacija,
      gradevinskaDozvola: dataToSend.ugovor,
      DatumPrijave: dataToSend.PocetakPrijave,
      DatumPocetak: dataToSend.Pocetak,
      DatumZavrsetak: dataToSend.Zavrsetak,
      ...coords,
      //add latitude and longitude
      // ADD inputs for Naziv
      //Check types
    };

    add(formatedData);
    handleNext();
  };
  const submitBack = (data) => {
    console.log(data);
    action(data);
    handleBack();
  };
  return (
    <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Row>
            <Col md="6">
              <Label for="PocetakPrijave">Početak prijave</Label>

              <Controller
                id="PocetakPrijave"
                as={<CustomDate autoOk={true} />}
                name="PocetakPrijave"
                control={control}
                defaultValue={
                  new Date(
                    state.data.PocetakPrijave === undefined
                      ? new Date().getTime()
                      : state.data.PocetakPrijave
                  )
                }
              />
            </Col>
            <Col md="6">
              <Label for="Pocetak">Početak</Label>

              <Controller
                id="Pocetak"
                as={<CustomDate autoOk={true} />}
                name="Pocetak"
                control={control}
                defaultValue={
                  new Date(
                    state.data.Pocetak === undefined
                      ? new Date().getTime()
                      : state.data.Pocetak
                  )
                }
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Row>
            <Col md="6">
              <Label for="Zavrsetak">Završetak</Label>

              <Controller
                id="Zavrsetak"
                as={<CustomDate autoOk={true} />}
                name="Zavrsetak"
                control={control}
                defaultValue={
                  new Date(
                    state.data.Zavrsetak === undefined
                      ? new Date().getTime()
                      : state.data.Zavrsetak
                  )
                }
              />
            </Col>
          </Row>
        </FormGroup>
        <Row style={{ marginTop: 20 }}>
          <Col>
            <Button onClick={handleSubmit(submitBack)}>Natrag</Button>

            <Button color="primary" type="submit">
              Završi
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Dates;
