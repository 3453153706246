import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import NumberFormat from "react-number-format";
import updateAction from "./updateAction";
import { useStateMachine } from "little-state-machine";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import { Error } from "../styles";

const formatCurrency = (value) =>
  new Intl.NumberFormat("hr-HR", {}).format(value);

const InputForm = ({ add }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors, setValue, getValues } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    action(data);
    add(data);
  };
  return (
    <>
      <div className="modal-body">
        <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Struka">Struka</Label>
                  <Controller
                    id="Struka"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Struka") && "inputError"
                        }`}
                        placeholder="Struka"
                        type="text"
                      />
                    }
                    defaultValue={state.troskovnikItem.Struka}
                    name="Struka"
                    control={control}
                    rules={{ required: "Struka je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Struka" />
                </Col>
                <Col md="6">
                  <Label for="Stavka">Stavka</Label>
                  <Controller
                    id="Stavka"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Stavka") && "inputError"
                        }`}
                        placeholder="Stavka"
                        type="text"
                      />
                    }
                    defaultValue={state.troskovnikItem.Stavka}
                    name="Stavka"
                    control={control}
                    rules={{ required: "Stavka je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Stavka" />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Opis">Opis</Label>
                  <Controller
                    id="Opis"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Opis") && "inputError"
                        }`}
                        placeholder="Opis"
                        type="text"
                      />
                    }
                    defaultValue={state.troskovnikItem.Opis}
                    name="Opis"
                    control={control}
                    rules={{ required: "Opis je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Opis" />
                </Col>
                <Col md="6">
                  <Label for="JedinicnaMjera">Jedinična mjera</Label>
                  <Controller
                    id="JedinicnaMjera"
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("JedinicnaMjera") &&
                          "inputError"
                        }`}
                        placeholder="Jedinična mjera"
                        type="text"
                      />
                    }
                    defaultValue={state.troskovnikItem.JedinicnaMjera}
                    name="JedinicnaMjera"
                    control={control}
                    rules={{ required: "Jedinična mjera je obvezno polje" }}
                  />
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="JedinicnaMjera"
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="KolicinaUkupno">Količina ukupno</Label>
                  <Controller
                    id="KolicinaUkupno"
                    as={
                      <NumberFormat
                        decimalScale={2}
                        fixedDecimalScale={true}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("KolicinaUkupno") &&
                          "inputError"
                        }`}
                        placeholder="Količina ukupno"
                        type="text"
                        customInput={Input}
                        onValueChange={(values) => {
                          const { JedinicnaCijena } = getValues();
                          const valueForSum = JedinicnaCijena
                            ? Number(
                                String(JedinicnaCijena)
                                  .replace(".", "")
                                  .replace(",", ".")
                              )
                            : 0;
                          const sum = (valueForSum * values.floatValue).toFixed(
                            2
                          );
                          setValue(
                            "UkupnaCijena",
                            isNaN(sum) ? 0 : formatCurrency(sum)
                          );
                          return values;
                        }}
                      ></NumberFormat>
                    }
                    defaultValue={state.troskovnikItem.KolicinaUkupno}
                    name="KolicinaUkupno"
                    control={control}
                    rules={{ required: "Količina ukupno je obvezno polje" }}
                  />
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="KolicinaUkupno"
                  />
                </Col>
                <Col md="6">
                  <Label for="JedinicnaCijena">Jedinična cijena</Label>
                  <InputGroup className="input-group-alternative mb-4">
                    <Controller
                      id="JedinicnaCijena"
                      as={
                        <NumberFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          className={`form-control-alternative ${
                            errors.hasOwnProperty("JedinicnaCijena") &&
                            "inputError"
                          }`}
                          placeholder="Jedinična cijena"
                          type="text"
                          customInput={Input}
                          onValueChange={(values) => {
                            const { KolicinaUkupno } = getValues();
                            console.log(KolicinaUkupno);
                            const value = Number(
                              String(KolicinaUkupno)
                                .replace(".", "")
                                .replace(",", ".")
                            );
                            const valueForSum = value ? value : 0;
                            const sum = (
                              valueForSum * values.floatValue
                            ).toFixed(2);

                            setValue(
                              "UkupnaCijena",
                              isNaN(sum) ? 0 : formatCurrency(sum)
                            );
                          }}
                        ></NumberFormat>
                      }
                      defaultValue={state.troskovnikItem.JedinicnaCijena}
                      name="JedinicnaCijena"
                      control={control}
                      rules={{ required: "Jedinična cijena je obvezno polje" }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>HRK</InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="JedinicnaCijena"
                  />
                </Col>
              </Row>
            </FormGroup>
            <Row className="justify-content-center">
              <Col md="6">
                <Label for="UkupnaCijena">Ukupna cijena</Label>
                <InputGroup className="input-group-alternative mb-4">
                  <Controller
                    id="UkupnaCijena"
                    as={
                      <Input
                        disabled
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("UkupnaCijena") && "inputError"
                        }`}
                        placeholder="Ukupna cijena"
                        type="text"
                      />
                    }
                    defaultValue={state.troskovnikItem.UkupnaCijena}
                    name="UkupnaCijena"
                    control={control}
                    rules={{ required: "Ukupna cijena je obvezno polje" }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>HRK</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <ErrorMessage as={Error} errors={errors} name="UkupnaCijena" />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }} className="justify-content-center">
              <Col md="2">
                <Button color="primary" type="submit">
                  Potvrdi
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <div className="modal-footer"></div>
    </>
  );
};

export default InputForm;
