import React from "react";
import { Button, Modal } from "reactstrap";
import FormStepper from "./FormStepper";

const FormModal = ({
  open,
  setOpen,
  setAktModal,
  user,
  add,
  reqError,
  status,
  isSuccess,
  size,
}) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size={"lg"}
      isOpen={open.state}
      toggle={() => setOpen({ ...open, state: !open.state })}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Unos podataka za naslovnicu
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          disabled={status === "loading" || status === "success"}
          type="button"
          onClick={() => open && setOpen({ ...open, state: !open.state })}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <FormStepper
          setOpen={setOpen}
          size={size}
          setAktModal={setAktModal}
          user={user}
          add={add}
          addStatus={status}
          isSuccess={isSuccess}
        />
      </div>
      <div className="modal-footer">
        {/*  <Button
          color="secondary"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen(!open)}
        >
          Close
        </Button>
        <Button color="primary" type="button">
          Save changes
        </Button> */}
      </div>
    </Modal>
  );
};

export default FormModal;
