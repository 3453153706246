import React from "react";
import Imenovanje from "../Akt/Imenovanja";
import CircularProgress from "@material-ui/core/CircularProgress";
import { StateMachineProvider } from "little-state-machine";
import { Button } from "reactstrap";
import style from "./Imenovanja.module.scss";
import Table from "../Table/Table";
import updateAction from "./updateAction";
import { useStateMachine } from "little-state-machine";
import DeleteModal from "./DeleteModal";
import { formatDate } from "../../helpers/functions";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";

const Akt = ({ aktModal, setAktModal, setAktToAdd, setAktovi, user }) => {
  const [rowToEdit, setRowToEdit] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({});
  const [reqError, setReqError] = React.useState({ state: false, message: "" });

  const { status, data, error, isLoading, isFetching } = useQuery(
    "aktovi",
    async () => {
      const {
        data,
      } = await handlePostRequest("https://building.plc.hr/api/c?m=AktoviGets", [
        { user: "igor", orgID: user.orgID },
      ]);
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        });
      console.log(formatData);
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );
  const [addAkt] = useMutation(
    async (dataToAdd) => {
      const a = {
        ID: aktModal.from === "edit" ? rowToEdit : 0,
        OrganizationID: user.orgID,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
        ...dataToAdd,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=AktoviAddEdit",
        [{ user: "igor", a }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("aktovi");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("aktovi");
          if (aktModal.open && !isFetching) {
            setAktModal({ ...aktModal, open: false });
          }
        }
      },
    }
  );
  const [deleteAkt] = useMutation(
    async () => {
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=AktoviDelete",
        [{ user: "igor", ID: rowToDelete.ID }]
      );
      return res;
    },
    {
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("aktovi");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("aktovi");
          if (modals.Delete && !isFetching) {
            setModals({ ...modals, Delete: false });
          }
        }
      },
    }
  );
  const [modals, setModals] = React.useState({
    Delete: false,
  });
  const { action, state } = useStateMachine(updateAction);

  const cols = React.useMemo(
    () => [
      /*   {
        accessor: "Struka",
        className: "alignCenter",
        Header: "Struka",
      }, */
      {
        accessor: "naziv",
        colClass: style.alignCenter,
        Header: "Naziv",
      },
      {
        accessor: "mjesto",
        Header: "Mjesto",
        colClass: style.alignCenter,
      },
      {
        accessor: "nadnevak",
        colClass: style.alignCenter,
        Header: "Nadnevak",
        Cell: (props) => formatDate(props.value),
      },
      {
        accessor: "Klasa",
        colClass: style.alignCenter,
        Header: "Klasa",
      },
      {
        accessor: "UrBroj",
        colClass: style.alignCenter,
        Header: "UrBroj",
      },
      {
        accessor: "Uredi",
        Header: "Uredi",
        className: "alignCenter",
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.editButton}
                type="button"
                onClick={(e) => {
                  setRowToEdit(props.row.original.ID);
                  const obj = props.row.original;
                  obj.nadnevak = new Date(props.row.original.nadnevak);
                  console.log(obj);
                  action(obj);
                  setAktModal({ open: true, from: "edit" });
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            );
          return null;
        },
      },
      {
        accessor: "Obrisi",
        Header: "Obriši",
        disableSortBy: true,
        className: "alignCenter",
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.deleteButton}
                type="button"
                onClick={() => {
                  setModals({ ...modals, Delete: true });
                  setRowToDelete(props.row.original);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            );
          return null;
        },
      },
    ],
    [modals, setAktModal, action]
  );

  return (
    <>
      <div>
        {status === "loading" ? (
          <div className="d-flex mx-auto justify-content-center mt-2">
            <CircularProgress size="4rem" color="primary" />
          </div>
        ) : (
          <div className="main-content">
            <section className={style.buttons}>
              <Button
                color="primary"
                onClick={() => setAktModal({ ...aktModal, open: true })}
              >
                Dodaj novi
              </Button>
            </section>

            <Table
              columns={cols}
              initialSort={[{ id: "Naziv", desc: false }]}
              data={data}
            ></Table>
          </div>
        )}
        <DeleteModal
          open={modals}
          setOpen={setModals}
          rowToDelete={rowToDelete}
          delFunc={deleteAkt}
        />
        <StateMachineProvider>
          <Imenovanje
            aktModal={aktModal}
            setAktModal={setAktModal}
            aktovi={data}
            setAktovi={setAktovi}
            setAktToAdd={setAktToAdd}
            add={addAkt}
          ></Imenovanje>
        </StateMachineProvider>
      </div>
    </>
  );
};

export default Akt;
