import React from "react";
import { Redirect } from "react-router-dom";
import { Button } from "reactstrap";
import { authenticationService } from "../../services/authentication";
import styled from "styled-components";

const ChooseRole = ({ user }) => {
  return user.role === undefined ? (
    <Wrapper>
      <Box>
        <h2 style={{ width: "100%" }}>Izaberite: </h2>
        <div>
          <Button
            color="primary"
            onClick={() => {
              const obj = { ...user, role: "nadzor" };
              authenticationService.updateUser(obj);
            }}
          >
            Nadzor
          </Button>
          <Button
            color="primary"
            onClick={() => {
              const obj = { ...user, role: "izvodac" };
              authenticationService.updateUser(obj);
            }}
          >
            Izvođač
          </Button>
        </div>
      </Box>
    </Wrapper>
  ) : (
    <Redirect
      to={{
        pathname: "/naslovnica",
        state: { from: "/" },
      }}
    />
  );
};

export default ChooseRole;

const Wrapper = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
`;
const Box = styled.div`
  width: calc(100% - 40px);
  background-color: #e9ebef;
  max-width: 400px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  border-radius: 10px;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  div {
    width: 100%;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
  }
`;
