import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import {
  Button,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  Form,
} from "reactstrap";
import updateAction from "./updateAction";
import { useStateMachine } from "little-state-machine";
import ReactDatetime from "react-datetime";
import "./Imenovanja.scss";
import { Error } from "../styles";
import CustomDate from "../CustomDate";

export default function Imenovanja({
  aktModal,
  setAktModal,
  aktovi,
  add,
  setAkt,
}) {
  const { control, errors, handleSubmit } = useForm();
  const { action, state } = useStateMachine(updateAction);

  const [error, setError] = React.useState("");

  const submit = (data) => {
    action(data);
    if (aktModal.from === "edit") {
      add(data);
    } else if (aktModal.from !== "edit" && aktModal.from !== "add") {
      const akts = aktovi.filter((item) => {
        if (item.naziv === data.naziv) {
          return true;
        } else {
          return false;
        }
      });
      if (akts.length === 0) {
        setError("");
        setAkt({ ...data, refresh: "refresh" });
        add(data);
        setTimeout(() => {
          setAktModal({ ...aktModal, open: false });
          action({
            Klasa: "",
            mjesto: "",
            nadnevak: new Date(),
            naziv: "",
            UrBroj: "",
          });
        }, 200);
      } else setError("Akt s tim nazivom već postoji");
    } else {
      const akts = aktovi.filter((item) => {
        if (item.naziv === data.naziv) {
          return true;
        } else {
          return false;
        }
      });
      if (akts.length === 0) {
        setError("");
        add(data);
        setTimeout(() => {
          setAktModal({ ...aktModal, open: false });
          action({
            Klasa: "",
            mjesto: "",
            nadnevak: new Date(),
            naziv: "",
            UrBroj: "",
          });
        }, 200);
      } else setError("Akt s tim nazivom već postoji");
    }
  };

  return (
    <div className="modal-body">
      <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
        <Form onSubmit={handleSubmit(submit)}>
          <Row>
            <Col className="mb-2">
              <Controller
                as={
                  <Input
                    className={`form-control-alternative ${
                      errors.hasOwnProperty("naziv") && "inputError"
                    }`}
                    placeholder="Naziv"
                    type="text"
                  />
                }
                defaultValue={state.akt.naziv}
                name="naziv"
                control={control}
                rules={{ required: "Naziv je obvezno polje" }}
              />
              <ErrorMessage as={Error} errors={errors} name="naziv" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Controller
                as={
                  <Input
                    className={`form-control-alternative ${
                      errors.hasOwnProperty("mjesto") && "inputError"
                    }`}
                    placeholder="Mjesto"
                    type="text"
                  />
                }
                defaultValue={state.akt.mjesto}
                name="mjesto"
                control={control}
                rules={{ required: "Mjesto je obvezno polje" }}
              />
              <ErrorMessage as={Error} errors={errors} name="mjesto" />
            </Col>
          </Row>
          <Row>
            <Col className="mb-2">
              <Controller
                style={{ width: "89%" }}
                as={<CustomDate autoOk={true} />}
                name="nadnevak"
                control={control}
                defaultValue={
                  new Date(
                    state.akt.nadnevak === undefined
                      ? new Date().getTime()
                      : state.akt.nadnevak
                  )
                }
              />
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <Controller
                as={
                  <Input
                    className={`form-control-alternative ${
                      errors.hasOwnProperty("Klasa") && "inputError"
                    }`}
                    placeholder="Klasa"
                    type="text"
                  />
                }
                defaultValue={state.akt.Klasa}
                name="Klasa"
                control={control}
                rules={{ required: "Klasa je obvezno polje" }}
              />
              <ErrorMessage as={Error} errors={errors} name="Klasa" />
            </Col>
          </Row>
          <Row>
            <Col>
              <Controller
                as={
                  <Input
                    className={`form-control-alternative ${
                      errors.hasOwnProperty("UrBroj") && "inputError"
                    }`}
                    placeholder="UrBroj"
                    type="text"
                  />
                }
                defaultValue={state.akt.UrBroj}
                name="UrBroj"
                control={control}
                rules={{ required: "UrBroj je obvezno polje" }}
              />
              <ErrorMessage as={Error} errors={errors} name="UrBroj" />
            </Col>
          </Row>
          {error.length !== 0 && (
            <Row>
              <Col>
                <h3 className="error">{error}</h3>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <Button
                color="primary"
                className="mx-auto mt-2 d-flex"
                onClick={handleSubmit(submit)}
              >
                Potvrdi
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </div>
  );
}
