import React from "react";
import NumberFormat from "react-number-format";
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import MaterijalTable from "../MaterijalTable/MaterijalTable";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import updateAction from "./updateAction";
import { useStateMachine } from "little-state-machine";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Select from "react-select";
import { Error } from "../styles";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery } from "react-query";
import ConfirmModal from "./ConfirmModal";
import CustomDate from "../CustomDate";

const customStyles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      width: "100%",
    };
  },
  control: (styles, { data }) => ({
    ...styles,
    boxShadow:
      " 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important",
    border: "0 !important",
    transition: " box-shadow 0.15s ease !important",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    width: "100%",
  }),
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <NumberFormat
      decimalScale={2}
      fixedDecimalScale={true}
      thousandSeparator={"."}
      decimalSeparator={","}
      placeholder="Količina ukupno"
      type="text"
      //customInput={Input}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={{ width: "100px" }}
    ></NumberFormat>
  );
};

const InputForm = ({ user, activePage, add, size }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = React.useState([]);
  const [tempPicked, setTempPicker] = React.useState([]);
  const [rowsPicked, setRowsPicked] = React.useState(false);
  const [pickError, setPickError] = React.useState({
    state: false,
    message: "",
  });
  const [warningModal, setWarningModal] = React.useState({
    state: false,
    data: {},
  });
  const onSubmit = (data) => {
    const hasLargerObj = { state: false };
    data.UtrosakMaterijala = tempPicked.map((item) => {
      const obj = {};

      for (let key in item) {
        if (
          key === "ID" ||
          key === "KolicinaUkupno" ||
          key === "TroskovnikID" ||
          key === "DatumUpis" ||
          key === "KolicinaUkupnoOld"
        ) {
          if (key === "KolicinaUkupno") {
            Object.assign(obj, {
              [key]: Number(
                String(item[key]).replace(".", "").replace(",", ".")
              ),
            });

            if (
              Number(String(item[key]).replace(".", "").replace(",", ".")) >
              item["KolicinaUkupnoOld"]
            ) {
              Object.assign(hasLargerObj, { state: true });
            }
          } else {
            Object.assign(obj, { [key]: item[key] });
          }
        }
      }

      return obj;
    });
    console.log(data, tempPicked);
    if (hasLargerObj.state) {
      console.log("warning, entered larger value");
      setWarningModal({ state: true, data: data });
    } else {
      action(data);
      add(data);
    }
  };

  //console.log(tempPicked);
  const [cols, setCols] = React.useState([
    {
      Header: "Odaberi",
      Cell: (props) => {
        return (
          <Checkbox
            color="primary"
            className="text-center"
            checked={props.row.original.checked}
            onChange={(event) => {
              //CHECK VALUE DISABLE RETRY ON FOCUS LOST
              props.row.original.checked = event.target.checked;
              props.row.original.DatumUpis = new Date();
              props.row.original.TroskovnikID = props.row.original.ID;
              props.row.original.ID = 0;
              props.row.original.KolicinaUkupnoOld =
                props.row.original.KolicinaUkupno;
              if (event.target.checked === true) {
                setTempPicker((tempPicked) => [
                  ...tempPicked,
                  props.row.original,
                ]);
              } else {
                setTempPicker((tempPicked) =>
                  tempPicked.filter((item) => item.ID !== props.row.original.ID)
                );
              }
            }}
          />
        );
      },
    },
    {
      accessor: "Stavka",
      className: "alignCenter",
      Header: "Stavka",
    },
    {
      accessor: "Opis",
      Header: "Opis",
      className: "alignCenter",
      colClass: "breakSpace",
    },
    {
      accessor: "JedinicnaMjera",
      className: "alignCenter",
      Header: "Jedinična mjera",
    },
  ]);
  const pickRows = () => {
    if (tempPicked.length < 1) {
      setPickError({ state: true, message: "Niste odabrali red." });
      setTimeout(() => {
        setPickError({ state: false, message: "" });
      }, 2500);
    } else {
      console.log(tempPicked);
      setRowsPicked(true);
      setCols((items) => items.filter((item) => item.Header !== "Odaberi"));
      setCols((items) => [
        ...items,
        {
          accessor: "KolicinaUkupno",
          Header: "Količina",
          Cell: EditableCell,
        },
      ]);
      setData(tempPicked);
    }
  };
  const troskovnikQuery = useQuery(
    "troskovnici",
    async () => {
      await sleep(300);

      const {
        data,
      } = await handlePostRequest(
        "http://building.plc.hr/api/c?m=TroskovnikGets",
        [{ user: "igor", naslovnicaID: activePage.id }]
      );
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => {
          return parsedData[key];
        })
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        })
        .map((item) => {
          item.checked = false;
          return item;
        });
      setData(formatData);
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount >= 3) {
          } else return true;
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const updateMyData = (rowIndex, columnId, value) => {
    setTempPicker((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );
  };
  const strojeviQuery = useQuery(
    "strojevi",
    async () => {
      await sleep(200);

      const {
        data,
      } = await handlePostRequest("http://building.plc.hr/api/c?m=StrojGets", [
        { user: "igor", orgID: user.orgID },
      ]);
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .map((item) => {
          return {
            ...item,
            label: item.MarkaStroja,
            value: item.ID,
          };
        })
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        });
      if (state.montazni.ID > 0) {
        const strojeviToAdd = formatData.filter((item) => {
          if (state.montazni.Strojevi.includes(item.ID)) {
            return true;
          } else return false;
        });
        console.log(strojeviToAdd);
        action({ Strojevi: strojeviToAdd });
        setValue("Strojevi", strojeviToAdd);
      }
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount >= 3) {
            //handle error
          } else return true;
        }
      },
    }
  );
  const zaposleniciQuery = useQuery(
    "zaposlenici",
    async () => {
      await sleep(100);
      const {
        data,
      } = await handlePostRequest(
        "http://building.plc.hr/api/c?m=ZaposlenikGets",
        [{ user: "igor", orgID: user.orgID }]
      );
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .map((item) => {
          return {
            ...item,
            label: item.Ime + " " + item.Prezime,
            value: item.ID,
          };
        })
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        });
      if (state.montazni.ID > 0) {
        const zaposleniciToAdd = formatData.filter((item) => {
          if (state.montazni.Zaposlenici.includes(item.ID)) {
            return true;
          } else return false;
        });
        action({ Zaposlenici: zaposleniciToAdd });
        setValue("Zaposlenici", zaposleniciToAdd);
      }
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );
  // console.log("troskovnik status", troskovnikQuery.isFetching);
  return (
    <>
      <div className="modal-body">
        <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
          {troskovnikQuery.status !== "success" ||
          strojeviQuery.status !== "success" ||
          zaposleniciQuery.status !== "success" ? (
            <CircularProgress
              size="4rem"
              style={{ margin: "auto" }}
              color="primary"
            />
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Row>
                  <Col md="6" className="mb-2 mb-md-0">
                    <Label for="PocetakRada">Početak rada</Label>
                    {/*   <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon> */}
                    <Controller
                      className={`form-control-alternative ${
                        errors.hasOwnProperty("PocetakRada") && "inputError"
                      }`}
                      id="PocetakRada"
                      as={<CustomDate autoOk={true} />}
                      name="PocetakRada"
                      control={control}
                      defaultValue={
                        new Date(
                          state.montazni.PocetakRada === undefined
                            ? new Date().getTime()
                            : state.montazni.PocetakRada
                        )
                      }
                    />

                    <ErrorMessage
                      as={Error}
                      errors={errors}
                      name="PocetakRada"
                    />
                  </Col>
                  <Col md="6">
                    <Label for="ZavrsetakRada">Završetak rada</Label>

                    <Controller
                      className={`form-control-alternative ${
                        errors.hasOwnProperty("ZavrsetakRada") && "inputError"
                      }`}
                      id="ZavrsetakRada"
                      as={<CustomDate autoOk={true} />}
                      name="ZavrsetakRada"
                      control={control}
                      defaultValue={
                        new Date(
                          state.montazni.ZavrsetakRada === undefined
                            ? new Date().getTime()
                            : state.montazni.ZavrsetakRada
                        )
                      }
                    />

                    <ErrorMessage
                      as={Error}
                      errors={errors}
                      name="ZavrsetakRada"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="6" className="mb-2 mb-md-0">
                    <Label for="Zaposlenici">Zaposlenici</Label>
                    <Controller
                      id="Zaposlenici"
                      as={<Select styles={customStyles} />}
                      defaultValue={state.montazni.Zaposlenici}
                      isMulti
                      placeholder="Odaberite Zaposlenike"
                      name="Zaposlenici"
                      control={control}
                      onChange={(value) => {
                        if (value[1].action === "clear") {
                          return null;
                        } else return value[0];
                      }}
                      rules={{ required: "Zaposlenici je obvezno polje" }}
                      options={zaposleniciQuery.data}
                    />
                    <ErrorMessage
                      as={Error}
                      errors={errors}
                      name="Zaposlenici"
                    />
                  </Col>
                  <Col md="6">
                    <Label for="Strojevi">Strojevi</Label>
                    <Controller
                      id="Strojevi"
                      as={<Select styles={customStyles} />}
                      defaultValue={state.montazni.Strojevi}
                      isMulti
                      placeholder="Odaberite strojeve"
                      name="Strojevi"
                      onChange={(value) => {
                        if (value[1].action === "clear") {
                          return null;
                        } else return value[0];
                      }}
                      control={control}
                      rules={{ required: "Strojevi je obvezno polje" }}
                      options={strojeviQuery.data}
                    />
                    <ErrorMessage as={Error} errors={errors} name="Strojevi" />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col md="6" className="mb-2 mb-md-0">
                    <Label for="UpisPoslovode">Upis poslovođe</Label>
                    <Controller
                      id="UpisPoslovode"
                      as={
                        <Input
                          className={`form-control-alternative ${
                            errors.hasOwnProperty("UpisPoslovode") &&
                            "inputError"
                          }`}
                          placeholder="Upis poslovođe"
                          type="text"
                        />
                      }
                      defaultValue={state.montazni.UpisPoslovode}
                      name="UpisPoslovode"
                      control={control}
                      rules={{ required: "Upis poslovođe je obvezno polje" }}
                    />
                    <ErrorMessage
                      as={Error}
                      errors={errors}
                      name="UpisPoslovode"
                    />
                  </Col>
                  <Col md="6">
                    <Label for="UpisNadzora">Upis nadzora</Label>
                    <Controller
                      id="UpisNadzora"
                      as={
                        <Input
                          className={`form-control-alternative ${
                            errors.hasOwnProperty("UpisNadzora") && "inputError"
                          }`}
                          placeholder="Upis nadzora"
                          type="text"
                        />
                      }
                      defaultValue={state.montazni.UpisNadzora}
                      name="UpisNadzora"
                      control={control}
                      rules={{ required: "Upis nadzora je obvezno polje" }}
                    />
                    <ErrorMessage
                      as={Error}
                      errors={errors}
                      name="JedinicnaCijena"
                    />
                  </Col>
                </Row>
              </FormGroup>
              <Row className="justify-content-center">
                <Col md="12">
                  {troskovnikQuery.isFetching ? (
                    <div className="d-flex mx-auto justify-content-center">
                      <CircularProgress size="4rem" color="primary" />
                    </div>
                  ) : (
                    <MaterijalTable
                      columns={cols}
                      updateMyData={updateMyData}
                      initialSort={[{ id: "Naziv", desc: false }]}
                      data={data}
                    />
                  )}
                  <ErrorMessage
                    as={Error}
                    errors={errors}
                    name="UstrosakMaterijala"
                  />
                </Col>
              </Row>
              {troskovnikQuery.status !==
              "success" ? null : rowsPicked ? null : (
                <Row
                  style={{ marginTop: 20 }}
                  className="justify-content-center"
                >
                  <Col md="3">
                    {troskovnikQuery.isFetching ? null : pickError.state ? (
                      <p className="error">{pickError.message}</p>
                    ) : (
                      <Button type="button" onClick={pickRows}>
                        Odaberi redove
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
              <Row style={{ marginTop: 20 }} className="justify-content-center">
                <Col md="2">
                  {troskovnikQuery.isFetching ? null : (
                    <Button color="primary" type="submit">
                      Potvrdi
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </Card>
      </div>
      <div className="modal-footer"></div>
      <ConfirmModal
        warningModal={warningModal}
        setWarningModal={setWarningModal}
        action={action}
        add={add}
      />
    </>
  );
};

export default InputForm;
