import React from "react";

import { Route, Redirect } from "react-router-dom";

import { authenticationService } from "../../services/authentication";

export function PrivateRoute({ checkRole, shouldRender, children, ...rest }) {
  const user = authenticationService.currentUserValue;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          checkRole ? (
            user.role && user.role === "izvodac" ? (
              shouldRender && shouldRender.state ? (
                children
              ) : (
                <Redirect
                  to={{
                    pathname: `${shouldRender.loc}`,
                    state: { from: location },
                  }}
                />
              )
            ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            )
          ) : shouldRender && shouldRender.state ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: `${shouldRender.loc}`,
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
