import React from "react";
import { authenticationService } from "../../services/authentication";
import { Button } from "reactstrap";
import style from "./Strojevi.module.scss";
import Table from "../Table/Table";
import FormModal from "./FormModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteModal from "./DeleteModal";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";

const Stojevi = ({ user }) => {
  const [rowToEdit, setRowToEdit] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [modals, setModals] = React.useState({
    Delete: false,
  });
  const { action, state } = useStateMachine(updateAction);
  const [open, setOpen] = React.useState({ state: false, from: "" });
  const [reqError, setReqError] = React.useState({ state: false, message: "" });

  const { status, data, error, isLoading, isFetching, refetch } = useQuery(
    "strojevi",
    async () => {
      const {
        data,
      } = await handlePostRequest("https://building.plc.hr/api/c?m=StrojGets", [
        { user: "igor", orgID: user.orgID },
      ]);
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        });

      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount >= 3) {
            authenticationService.logout();
          } else return true;
        }
      },
    }
  );

  const [addStroj] = useMutation(
    async (dataToAdd) => {
      const s = {
        ID: open.from === "edit" ? rowToEdit : 0,
        OrganizationID: user.orgID,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
        ...dataToAdd,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=StrojAddEdit",
        [{ user: "igor", s }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("strojevi");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("strojevi");
          if (open.state && !isFetching) {
            setOpen({ ...open, state: false });
          }
        }
      },
    }
  );
  const [deleteStroj] = useMutation(
    async () => {
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=StrojDelete",
        [{ user: "igor", ID: rowToDelete.ID }]
      );
      return res;
    },
    {
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("strojevi");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("strojevi");
          if (modals.Delete && !isFetching) {
            setModals({ ...modals, Delete: false });
          }
        }
      },
    }
  );
  const cols = React.useMemo(
    () => [
      /*   {
        accessor: "Struka",
        className: "alignCenter",
        Header: "Struka",
      }, */
      {
        accessor: "TipStroja",
        colClass: style.alignCenter,
        Header: "Tip stroja",
      },
      {
        accessor: "MarkaStroja",
        Header: "Marka stroja",
        colClass: style.alignCenter,
      },
      {
        accessor: "RegistracijskaOznaka",
        colClass: style.alignCenter,
        Header: "RegistracijskaOznaka",
      },

      {
        accessor: "Uredi",
        Header: "Uredi",
        className: "alignCenter",
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.editButton}
                type="button"
                onClick={(e) => {
                  setRowToEdit(props.row.original.ID);
                  const obj = {
                    TipStroja: props.row.original.TipStroja,
                    MarkaStroja: props.row.original.MarkaStroja,
                    RegistracijskaOznaka:
                      props.row.original.RegistracijskaOznaka,
                  };

                  //GET BY ID AND CALL ACTION
                  //SET FLAG TO EDIT AND OPEN MODAL
                  action(obj);
                  setOpen({ state: true, from: "edit" });
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            );
          return null;
        },
      },
      {
        accessor: "Obrisi",
        Header: "Obriši",
        disableSortBy: true,
        className: "alignCenter",
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.deleteButton}
                type="button"
                onClick={() => {
                  setModals({ ...modals, Delete: true });
                  setRowToDelete(props.row.original);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            );
          return null;
        },
      },
    ],
    [modals, action]
  );

  return (
    <>
      {status === "loading" ? (
        <div className="d-flex mx-auto justify-content-center mt-2">
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : (
        <div>
          <div className="main-content">
            <section className={style.buttons}>
              <Button
                color="primary"
                onClick={() => {
                  setOpen({ state: !open.state, from: "add" });
                  action({
                    TipStroja: "",
                    MarkaStroja: "",
                    RegistracijskaOznaka: "",
                  });
                }}
              >
                Dodaj novi
              </Button>
            </section>

            <Table
              columns={cols}
              initialSort={[{ id: "Naziv", desc: false }]}
              data={data}
            ></Table>
          </div>
        </div>
      )}
      <FormModal
        open={open}
        setOpen={setOpen}
        addStroj={addStroj}
        reqError={reqError}
      />
      <DeleteModal
        open={modals}
        setOpen={setModals}
        rowToDelete={rowToDelete}
        deleteStroj={deleteStroj}
      ></DeleteModal>
    </>
  );
};

export default Stojevi;
