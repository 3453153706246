import React from "react";
import NumberFormat from "react-number-format";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import updateAction from "./updateAction";
import { useStateMachine } from "little-state-machine";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import Select from "react-select";
import { Error } from "../styles";
import CustomDate from "../CustomDate";

const customStyles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      width: "100%",
    };
  },
  control: (styles, { data }) => ({
    ...styles,
    boxShadow:
      " 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important",
    border: "0 !important",
    transition: " box-shadow 0.15s ease !important",
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    width: "100%",
  }),
};

const NadzorInputForm = ({ user, add }) => {
  const { action, state } = useStateMachine(updateAction);
  const { control, handleSubmit, errors } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    action(data);
    add(data);
  };

  return (
    <>
      <div className="modal-body">
        <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Row>
                <Col md="6" className="mb-2 mb-md-0">
                  <Label for="Opis">Opis</Label>
                  <Controller
                    as={
                      <Input
                        className={`form-control-alternative ${
                          errors.hasOwnProperty("Opis") && "inputError"
                        }`}
                        placeholder="Opis"
                        type="text"
                      />
                    }
                    id="Opis"
                    defaultValue={state.nadzorUpis.Opis}
                    name="Opis"
                    control={control}
                    rules={{ required: "Opis je obvezno polje" }}
                  />
                  <ErrorMessage as={Error} errors={errors} name="Opis" />
                </Col>
                <Col md="6">
                  <Label for="DatumUpisa">Datum upisa</Label>

                  <Controller
                    id="DatumUpisa"
                    as={<CustomDate autoOk={true} />}
                    name="DatumUpis"
                    control={control}
                    defaultValue={
                      new Date(
                        state.nadzorUpis.DatumUpis === undefined
                          ? new Date().getTime()
                          : state.nadzorUpis.DatumUpis
                      )
                    }
                  />

                  <ErrorMessage as={Error} errors={errors} name="DatumUpis" />
                </Col>
              </Row>
            </FormGroup>

            <Row style={{ marginTop: 20 }} className="justify-content-center">
              <Col md="2">
                <Button color="primary" type="submit">
                  Potvrdi
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
      <div className="modal-footer"></div>
    </>
  );
};

export default NadzorInputForm;
