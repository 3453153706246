import React from "react";
import { StateMachineProvider } from "little-state-machine";
import { Modal } from "reactstrap";
import InputForm from "./InputForm";

const FormModal = ({ open, setOpen, add, reqError }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size={"lg"}
      isOpen={open.state}
      toggle={() => setOpen({ ...open, state: !open.state })}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Novi unos
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen({ ...open, state: !open.state })}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <StateMachineProvider>
        <InputForm add={add} reqError={reqError} />
      </StateMachineProvider>
    </Modal>
  );
};

export default FormModal;
