import React from "react";
import { Modal, Button } from "reactstrap";
import { formatDate } from "../../helpers/functions";

const DeleteModal = ({ open, setOpen, rowToDelete, delFunc }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size={"lg"}
      isOpen={open.Delete}
      toggle={() => setOpen({ ...open, Delete: !open.Delete })}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Obriši akt
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen({ ...open, Delete: !open.Delete })}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      {open.Delete ? (
        <div className="mx-auto">
          <h2 className="ml-4 ml-sm-0">Jeste li sigurni da želite obrisati akt?</h2>
          <ul>
            <li>
              Naziv:{" "}
              <span className="font-weight-bold"> {rowToDelete.naziv}</span>
            </li>
            <li>
              Mjesto:{" "}
              <span className="font-weight-bold"> {rowToDelete.mjesto}</span>
            </li>
            <li>
              Nadnevak:{" "}
              <span className="font-weight-bold">
                {" "}
                {formatDate(rowToDelete.nadnevak)}
              </span>
            </li>
            <li>
              Klasa:{" "}
              <span className="font-weight-bold"> {rowToDelete.Klasa}</span>
            </li>
            <li>
              UrBroj:{" "}
              <span className="font-weight-bold"> {rowToDelete.UrBroj}</span>
            </li>
          </ul>
          <Button
            className="mx-auto my-2 d-flex"
            color="danger"
            onClick={delFunc}
          >
            Obriši
          </Button>
        </div>
      ) : null}
    </Modal>
  );
};

export default DeleteModal;
