import React from "react";
import { useTable, useSortBy } from "react-table";
import "./Table.scss";

const Table = ({ columns, data, initialSort }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      //add disablesortby
      columns,
      data,
      initialState: { sortBy: initialSort },
    },

    useSortBy
  );
  const firstPageRows = rows.slice(0, 20);

  return (
    <>
      <div className="react-bootstrap table-responsive">
        <table className="table table-bordered" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={column.className}
                  >
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    {column.disableCaret ? null : (
                      <span style={{ marginLeft: 5 }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <>
                              <i
                                className="fas fa-angle-up"
                                style={{ opacity: 0.3 }}
                              ></i>
                              <i
                                style={{ marginLeft: 5 }}
                                className="fas fa-angle-down"
                              ></i>
                            </>
                          ) : (
                            <>
                              <i style={{}} className="fas fa-angle-up"></i>
                              <i
                                className="fas fa-angle-down"
                                style={{ opacity: 0.3, marginLeft: 5 }}
                              ></i>
                            </>
                          )
                        ) : (
                          <>
                            <i
                              className="fas fa-angle-up"
                              style={{ opacity: 0.3 }}
                            ></i>
                            <i
                              className="fas fa-angle-down"
                              style={{ opacity: 0.3, marginLeft: 5 }}
                            ></i>
                          </>
                        )}
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {firstPageRows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className={cell.column.colClass}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
