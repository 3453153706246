import { BehaviorSubject } from "rxjs";
import { handleResponse } from "../helpers/handle-response";
import jwt_decode from "jwt-decode";

if (sessionStorage.getItem("user") === "") {
  sessionStorage.removeItem("user");
}
if (sessionStorage.getItem("naslovnica") === "") {
  sessionStorage.removeItem("naslovnica");
}
const currentUserSubject = new BehaviorSubject(
  JSON.parse(sessionStorage.getItem("user"))
);
const currentNaslovnicaSubject = new BehaviorSubject(
  JSON.parse(sessionStorage.getItem("naslovnica"))
);
export const authenticationService = {
  login,
  logout,
  register,
  setUser,
  updateUser,
  user: currentUserSubject.asObservable(),
  get currentUserValue() {
    return currentUserSubject.value;
  },
  naslovnica: currentNaslovnicaSubject.asObservable(),
  get currentNaslovnicaValue() {
    return currentNaslovnicaSubject.value;
  },
  setNaslovnica,
};

function setUser(user) {
  sessionStorage.setItem("user", JSON.stringify(user));
  currentUserSubject.next(user);
  return user;
}
function setNaslovnica(naslovnica) {
  sessionStorage.setItem("naslovnica", JSON.stringify(naslovnica));
  currentNaslovnicaSubject.next(naslovnica);
  return naslovnica;
}
async function login(username, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };

  return fetch(
    `${process.env.REACT_APP_HOST_URL}/users/login`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      const decoded = jwt_decode(res);
      const user = {
        id: decoded.id,
        username: decoded.username,
        email: decoded.email,
        orgID: decoded.orgID,
        token: res,
      };
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      setUser(user);
      return user;
    })
    .catch((error) => {
      return error;
    });
  /* setUser({ username, password, orgID: 3, email: "test@email.com" });
  return { username, password, orgID: 3, email: "test@email.com" }; */
}

function logout() {
  // remove user from local storage to log user out
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("naslovnica");
  currentNaslovnicaSubject.next(null);
  currentUserSubject.next(null);
}

function register(values) {
  const tempData = new FormData();
  for (var value in values) {
    tempData.append(value, values[value]);
    /* if (values[value] === undefined || values[value] === "") {
      tempData.append(value, "");
    } */
  }
  const requestOptions = {
    method: "POST",
    body: tempData,
  };

  return fetch(
    `${process.env.REACT_APP_HOST_URL}:2099/users/register`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      console.log("error: " + error);
    });
}
function updateUser(user) {
  setUser(user);
}
