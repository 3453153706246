import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import "./TabMenu.scss";

const tabItems = [
  { tab: 1, name: "Troškovnik", component: "/troskovnici" },
  { tab: 2, name: "Montažni dnevnik", component: "/montazni-dnevnik" },
  { tab: 3, name: "Građevinski dnevnik", component: "/gradevinski-dnevnik" },
  { tab: 4, name: "Građevinska knjiga", component: "/gradevinska-knjiga" },
  { tab: 5, name: "Situacija", component: "/situacija" },
];
const nadzorTabItems = [
  {
    tab: 1,
    name: "Nadzor dnevnik",
    component: "/gradevinski-dnevnik",
  },
];
const initialTabItems = [
  { tab: 1, name: "Naslovnica", component: "/naslovnica" },
  { tab: 2, name: "Organizacije", component: "/organizacije" },
  { tab: 3, name: "Zaposlenici", component: "/zaposlenici" },
  { tab: 4, name: "Strojevi", component: "/strojevi" },
  { tab: 5, name: "Aktovi", component: "/aktovi" },
];

const TabMenu = (props) => {
  const { history, activeTab, setActiveTab, activePage, hide } = props;
  const tabs =
    activePage.name.length !== 0
      ? props.role === "nadzor"
        ? nadzorTabItems
        : tabItems
      : initialTabItems;
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  React.useEffect(() => {
    const tab = tabs.find((item) => {
      if (item.component === history.location.pathname) {
        return item;
      }
      return null;
    });
    setActiveTab(tab ? tab.tab : 1);
  });
  return (
    <div className={"tabMenu"} style={hide ? { display: "none" } : {}}>
      <Nav tabs>
        {tabs.map((item, index) => {
          return (
            <NavItem key={item.name + index}>
              <NavLink
                className={classnames({
                  active: activeTab === index + 1,
                  activeTab: activeTab === index + 1,
                })}
                style={{ cursor: "pointer", fontSize: 17 }}
                onClick={() => {
                  toggle(index + 1);
                  history.push(item.component);
                }}
              >
                {item.name}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      {/*   <TabContent activeTab={activeTab} style={{ marginTop: 15 }}>
        {tabItems.map((item, index) => {
          return <TabPane tabId={index + 1}>{item.component}</TabPane>;
        })}
      </TabContent> */}
    </div>
  );
};

export default TabMenu;
