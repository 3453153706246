import React from "react";
import { authenticationService } from "../../services/authentication";
import { useHistory } from "react-router-dom";
import { formatDateToString } from "../../utils/functions";
import FormModal from "./FormModal";
import { Button } from "reactstrap";
import style from "./Naslovnica.module.scss";
import Table from "../Table/Table";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteModal from "./DeleteModal";
import { useStateMachine } from "little-state-machine";
import updateAction from "./Form/updateAction";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";
import {
  formatCurrency,
  formatNaslovnicaData,
  sleep,
} from "../../helpers/functions";
import PickTemplate from "./PickTemplate";

const NaslovnicaNadzor = ({ setAktModal, handleSetActive, user, size }) => {
  const [rowToEdit, setRowToEdit] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({});

  React.useEffect(() => {
    authenticationService.naslovnica.subscribe((x) => {
      if (x) {
        if (x.id > 0) {
          history.push("/gradevinski-dnevnik");
        }
      }
    });
  }, []);
  const [modals, setModals] = React.useState({
    Delete: false,
  });
  let history = useHistory();
  const [open, setOpen] = React.useState({ from: "", state: false });
  const [templateModal, setTemplateModal] = React.useState({ state: false });
  const { action, state } = useStateMachine(updateAction);

  const [reqError, setReqError] = React.useState({ state: false, message: "" });

  const { status, data, error, isLoading, isFetching } = useQuery(
    "naslovniceNadzor",
    async () => {
      await sleep(200);
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=NaslovnicaNadzorGets",
        [{ user: "igor" }]
      );
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return false;
        });
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount === 3) {
            authenticationService.logout();
          }
          return true;
        }
      },
    }
  );
  console.log(data, status, user);
  const [addNaslovnica, { status: addStatus, isSuccess }] = useMutation(
    async (dataToAdd) => {
      const n = {
        ...dataToAdd,
        ID: open.from === "edit" ? rowToEdit : 0,
        OrganizationID: user.orgID,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=NaslovnicaNadzorAddEdit",
        [{ user: "igor", n, ip: "" }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
        setTimeout(() => {
          open && setOpen({ from: "", open: false });
        }, 3000);
      },
      onSettled: (res) => {
        if (res && res.data === "False") {
          queryCache.invalidateQueries("naslovniceNadzor");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else if (!res) {
          setTimeout(() => {
            setOpen({ from: "", open: false });
          }, 3000);
        } else {
          // refetch();
          queryCache.invalidateQueries("naslovniceNadzor");
          // FIX CLOSE MODAL
          if (open.state) {
            setTimeout(() => {
              open && setOpen({ ...open, state: false });
            }, 2000);
          }
        }
      },
    }
  );
  //console.log("check status", addStatus, isSuccess);
  const [deleteNaslovnica] = useMutation(
    async () => {
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=NaslovnicaNadzorDelete",
        [{ user: "igor", ID: rowToDelete.ID }]
      );
      return res;
    },
    {
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("naslovniceNadzor");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("naslovniceNadzor");
          if (modals.Delete && !isFetching) {
            setModals({ ...modals, Delete: false });
          }
        }
      },
    }
  );

  const cols = React.useMemo(
    () => [
      {
        accessor: "Odaberi",
        className: "alignCenter",
        Header: "Odaberi",
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <Button
                size="sm"
                color="default"
                type="button"
                style={{ display: "flex", margin: "auto" }}
                onClick={() => {
                  console.log("odaberi called", props.row.original);
                  handleSetActive(
                    props.row.original.Naziv,
                    props.row.original.ID
                  );
                  authenticationService.setNaslovnica({
                    name: props.row.original.Naziv,
                    id: props.row.original.ID,
                  });
                  history.push("/gradevinski-dnevnik");
                }}
              >
                Odaberi
              </Button>
            );
          return null;
        },
      },
      {
        accessor: "Naziv",
        Header: "Naziv",
        className: style.alignCenter,
      },
      {
        accessor: "CijenaBezPdv",
        className: style.alignCenter,
        Header: "Cijena bez PDV-a",
        colClass: "text-right",
        Cell: (props) => formatCurrency(props.cell.value),
      },
      { accessor: "Izvodac", Header: "Izvođač", className: style.alignCenter },
      {
        accessor: "Investitor",
        Header: "Investitor",
        className: style.alignCenter,
      },
      {
        accessor: "Lokacija",
        Header: "Lokacija",
        className: style.alignCenter,
      },

      {
        accessor: "DatumPrijave",
        Header: "Datum prijave",
        className: style.alignCenter,
        Cell: (props) => (
          <div className="text-center">
            {formatDateToString(props.cell.value)}
          </div>
        ),
      },

      {
        accessor: "Uredi",
        Header: "Uredi",
        className: style.alignCenter,
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.editButton}
                type="button"
                onClick={(e) => {
                  setRowToEdit(props.row.original.ID);

                  //GET BY ID AND CALL ACTION
                  //SET FLAG TO EDIT AND OPEN MODAL
                  const rowData = props.row.original;
                  const data = formatNaslovnicaData(rowData);
                  action(data);
                  setOpen({ state: true, from: "edit" });
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            );
          return null;
        },
      },
      {
        accessor: "Obrisi",
        Header: "Obriši",
        disableSortBy: true,
        className: style.alignCenter,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.deleteButton}
                type="button"
                onClick={() => {
                  setModals({ ...modals, Delete: true });
                  setRowToDelete(props.row.original);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            );
          return null;
        },
      },
    ],
    [modals, history, handleSetActive, action]
  );

  return (
    <>
      <div>
        {status !== "success" ? (
          <div className="d-flex mx-auto justify-content-center mt-2">
            <CircularProgress size="4rem" color="primary" />
          </div>
        ) : (
          <div className="main-content">
            <section className={style.buttons}>
              <Button
                className="mb-2 mb-sm-0 mr-6 mr-sm-2"
                color="primary"
                type="button"
                onClick={() => {
                  //RESET DATA ON ADD NEW
                  const obj = {
                    CijenaBezPdv: 0,
                    Cijena: 0,
                    DatumPocetak: new Date(),
                    DatumPrijave: new Date(),
                    DatumZavrsetak: new Date(),
                    Gradevina: "",
                    ID: 0,
                    Investitor: "",
                    Izvodac: "",
                    LatitudeSirinaSJ: 0,
                    Lokacija: "",
                    LongitudeDuzinaIZ: 0,
                    Naziv: "",
                    GlavniInzenjer: null,
                    AktImenovanje: null,
                    GlavniNadzor: null,
                    AktGlavni: null,
                    Akt1: null,
                    Akt2: null,
                    Akt3: null,
                    Nadzor1: null,
                    Nadzor2: null,
                    Nadzor3: null,
                    ugovor: null,
                    Voditelj: null,
                  };
                  action(obj);
                  setOpen({ from: "add", state: true });
                }}
              >
                Dodaj novu
              </Button>
              <Button
                className="ml-0"
                color="primary"
                onClick={() => setTemplateModal({ state: true })}
              >
                Dodaj novu iz predloška
              </Button>
            </section>

            <Table
              columns={cols}
              initialSort={[{ id: "Prezime", desc: false }]}
              data={data}
            ></Table>
          </div>
        )}
        <FormModal
          open={open}
          setOpen={setOpen}
          size={size}
          setAktModal={setAktModal}
          user={user}
          add={addNaslovnica}
          reqError={reqError}
          status={addStatus}
          isSuccess={isSuccess}
        />
        {templateModal.state ? (
          <PickTemplate
            user={user}
            open={templateModal}
            setOpen={setTemplateModal}
            setOpenNaslovnica={setOpen}
            role="Nadzor"
          ></PickTemplate>
        ) : null}
        <DeleteModal
          open={modals}
          setOpen={setModals}
          rowToDelete={rowToDelete}
          delFunc={deleteNaslovnica}
        ></DeleteModal>
      </div>
    </>
  );
};

export default NaslovnicaNadzor;
