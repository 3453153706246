import React from "react";
import { authenticationService } from "../../services/authentication";
import { Button } from "reactstrap";
import style from "./Troskovnik.module.scss";
import Table from "../Table/Table";
import FormModal from "./FormModal";
import DeleteModal from "./DeleteModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";
import { formatCurrency } from "../../helpers/functions";

const Troskovnik = ({ user, activePage }) => {
  const [rowToEdit, setRowToEdit] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({});

  const [reqError, setReqError] = React.useState({ state: false, message: "" });
  const { action, state } = useStateMachine(updateAction);

  const [modals, setModals] = React.useState({
    Delete: false,
  });
  const [open, setOpen] = React.useState({ from: "", state: false });

  const { status, data, error, isLoading, isFetching, refetch } = useQuery(
    "troskovnici",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=TroskovnikGets",
        [{ user: "igor", naslovnicaID: activePage.id }]
      );
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => {
          if (key === "KolicinaUkupno" || key === "JedinicnaCijena") {
            return String(parsedData[key]);
          } else return parsedData[key];
        })
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        });

      console.log(formatData);
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount >= 3) {
            authenticationService.logout();
          } else return true;
        }
      },
    }
  );

  const [addTroskovnik] = useMutation(
    async (dataToAdd) => {
      console.log(dataToAdd);
      const ti = {
        ...dataToAdd,
        ID: open.from === "edit" ? rowToEdit : 0,
        OrganizationID: user.orgID,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
        JedinicnaCijena: Number(
          String(dataToAdd.JedinicnaCijena).replace(".", "").replace(",", ".")
        ),
        KolicinaUkupno: Number(
          String(dataToAdd.KolicinaUkupno).replace(".", "").replace(",", ".")
        ),
        UkupnaCijena: Number(
          String(dataToAdd.UkupnaCijena).replace(".", "").replace(",", ".")
        ),
        NaslovnicaID: activePage.id,
      };
      console.log(ti);
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=TroskovnikAddEdit",
        [{ user: "igor", ti, ip: "" }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("troskovnici");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("troskovnici");
          if (open.state && !isFetching) {
            setOpen({ ...open, state: false });
          }
        }
      },
    }
  );
  const [deleteTroskovnik] = useMutation(
    async () => {
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=TroskovnikDelete",
        [{ user: "igor", ID: rowToDelete.ID }]
      );
      return res;
    },
    {
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("troskovnici");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("troskovnici");
          if (modals.Delete && !isFetching) {
            setModals({ ...modals, Delete: false });
          }
        }
      },
    }
  );
  const cols = React.useMemo(
    () => [
      /*   {
        accessor: "Struka",
        className: "alignCenter",
        Header: "Struka",
      }, */
      {
        accessor: "Stavka",
        className: "alignCenter",
        Header: "Stavka",
      },
      {
        accessor: "Opis",
        Header: "Opis",
        className: "alignCenter",
      },
      {
        accessor: "JedinicnaMjera",
        className: "alignCenter",
        Header: "Jedinična mjera",
      },
      {
        accessor: "KolicinaUkupno",
        Header: "Količina ukupno",
        className: "alignCenter",
        Cell: (props) => formatCurrency(props.cell.value),
      },
      {
        Header: "Jedinična cijena",
        accessor: "JedinicnaCijena",
        className: "alignCenter",
        colClass: style.alignRight,
        Cell: (props) => formatCurrency(props.cell.value),
      },
      {
        Header: "Ukupna cijena",
        accessor: "UkupnaCijena",
        className: "alignCenter",
        colClass: style.alignRight,
        Cell: (props) => formatCurrency(props.cell.value),
      },
      {
        accessor: "Uredi",
        Header: "Uredi",
        className: "alignCenter",
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.editButton}
                type="button"
                onClick={(e) => {
                  setRowToEdit(props.row.original.ID);
                  action(props.row.original);
                  setOpen({ state: true, from: "edit" });
                  setModals({ ...modals, AddEdit: true });
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            );
          return null;
        },
      },
      {
        accessor: "Obrisi",
        Header: "Obriši",
        disableSortBy: true,
        className: "alignCenter",
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.deleteButton}
                type="button"
                onClick={() => {
                  setModals({ ...modals, Delete: true });
                  setRowToDelete(props.row.original);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            );
          return null;
        },
      },
    ],
    [modals, action]
  );

  return (
    <>
      {status === "loading" ? (
        <div className="d-flex mx-auto justify-content-center mt-2">
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : (
        <div>
          <div className="main-content">
            <section className={style.buttons}>
              <Button
                color="primary"
                onClick={() => {
                  action({
                    Investitor: "",
                    Izvodac: "",
                    JedinicnaCijena: "",
                    JedinicnaMjera: "",
                    KolicinaUkupno: "",
                    NaslovnicaID: "",
                    Opis: "",
                    Stavka: "",
                    Struka: "",
                    UkupnaCijena: "",
                  });
                  setOpen({ state: true, from: "add" });
                }}
              >
                Dodaj novi
              </Button>
            </section>

            <Table
              columns={cols}
              initialSort={[{ id: "Naziv", desc: false }]}
              data={data}
            ></Table>
          </div>
        </div>
      )}
      <FormModal
        open={open}
        setOpen={setOpen}
        add={addTroskovnik}
        delFunc={deleteTroskovnik}
      />
      <DeleteModal
        open={modals}
        setOpen={setModals}
        rowToDelete={rowToDelete}
        deleteStroj={deleteTroskovnik}
      ></DeleteModal>
    </>
  );
};

export default Troskovnik;
