import React from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { authenticationService } from "../../services/authentication";
import CircularProgress from "@material-ui/core/CircularProgress";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { handlePostRequest } from "../../helpers/handle-response";
import "./Login.scss";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const Login = (props) => {
  const [loading, setLoading] = React.useState(false);
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const [error, setError] = React.useState("");

  const onSubmit = (data) => {
    setLoading(true);
    authenticationService
      .login(data.username, data.password)
      .then((res) => {
        console.log(res);
        if (
          res === "Pogrešno korisničko ime ili lozinka" ||
          res === "Korisničko ime ne postoji"
        ) {
          setLoading(false);
          setError(res);
          setTimeout(() => {
            setError("");
          }, 3000);
        } else {
          console.log("is push called");
          handlePostRequest("https://building.plc.hr/api/c?m=Load", [
            {
              user: "igor",
              password: "parpar66", //makeid(data.username.length),
            },
          ]).then((res) => {
            if (res.data === "True") {
              history.push("/");
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: 30 }}>
      <Col lg="3" md="6">
        <Card className="bg-secondary shadow border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <h2>Prijavite se</h2>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    className="form-control"
                    placeholder="Korisničko ime"
                    type="text"
                    name="username"
                    ref={register({ required: true })}
                  />
                </InputGroup>
                {errors.username && (
                  <p className="error">Korisničko ime je obvezno</p>
                )}
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <input
                    className="form-control"
                    placeholder="Lozinka"
                    type="password"
                    name="password"
                    ref={register({ required: true })}
                  />
                </InputGroup>
                {errors.password && <p className="error">Lozinka je obvezna</p>}
              </FormGroup>
              {error.length > 0 ? (
                <div className="text-center error">
                  <h4 className="text-danger">{error}</h4>
                </div>
              ) : null}
              <div className="text-center">
                {loading ? (
                  <div className="d-flex mx-auto justify-content-center mt-2">
                    <CircularProgress size="3rem" color="primary" />
                  </div>
                ) : (
                  <Button
                    disabled={error.length > 0 ? true : false}
                    className="my-4"
                    color="primary"
                    type="submit"
                  >
                    Prijava
                  </Button>
                )}
              </div>
            </Form>
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default Login;
