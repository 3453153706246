import React from "react";
import format from "date-fns/format";
import { hr } from "date-fns/locale";
import classnames from "classnames";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";

import "./CustomDate.scss";

const CustomInput = (props) => {
  return (
    <Input
      onFocus={() => props.setFocus(true)}
      onBlur={() => props.setFocus(false)}
      value={props.value}
      onChange={props.onChange}
    />
  );
};
const Toolbar = (props) => {
  return (
    <div className="toolbar-wrapper">
      <div className="box">
        <button
          className={classnames({
            item: true,
            "item-1": true,
            active: props.openView === "year",
          })}
          onClick={() => props.setOpenView("year")}
        >
          {props.date.getFullYear()}
        </button>
        <button
          className={classnames({
            item: true,
            "item-2": true,
            active: props.openView === "month",
          })}
          onClick={() => props.setOpenView("month")}
        >
          {format(new Date(props.date), "LLLL", {
            locale: hr,
          })}
        </button>
        <button
          className={classnames({
            item: true,
            "item-3": true,
            active: props.openView === "date",
          })}
          onClick={() => props.setOpenView("date")}
        >
          {format(new Date(props.date), "do MMM, yyyy", {
            locale: hr,
          })}
        </button>
      </div>
    </div>
  );
};

const CustomDate = (props) => {
  //const [selectedDate, setDate] = React.useState(new Date());
  const [isOpen, setIsOpen] = React.useState(false);
  const [isFocused, setFocus] = React.useState(false);
  return (
    <>
      <MuiPickersUtilsProvider
        className="datepicker"
        utils={DateFnsUtils}
        locale={hr}
      >
        <InputGroup
          className={classnames({ "custom-input": true, focused: isFocused })}
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText
              onClick={() => setIsOpen(!isOpen)}
              className="datepicker-icon"
            >
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
          <KeyboardDatePicker
            {...props}
            open={isOpen}
            isFocused={isFocused}
            setFocus={setFocus}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            format="dd.MM.yyyy"
            label="Basic example"
            /*  value={selectedDate}
            onChange={(date) => setDate(date)} */
            openTo="date"
            views={["date"]}
            TextFieldComponent={CustomInput}
            ToolbarComponent={Toolbar}
          />
        </InputGroup>
      </MuiPickersUtilsProvider>
    </>
  );
};
export default CustomDate;
