import React from "react";
import { useQuery } from "react-query";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { Modal, Button, Card, Row, Col, Label, Form } from "reactstrap";
import updateAction from "./Form/updateAction";
import { useStateMachine } from "little-state-machine";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Error } from "../styles";
import { formatNaslovnicaData, sleep } from "../../helpers/functions";
import { handlePostRequest } from "../../helpers/handle-response";
import Select from "react-select";
import "./PickTemplate.scss";

const PickTemplate = ({ open, setOpen, user, setOpenNaslovnica, role }) => {
  const { control, handleSubmit, errors } = useForm({});
  const { action } = useStateMachine(updateAction);
  const { /*  status, */ data, /* error,  */ isLoading, isFetching } = useQuery(
    "templates",
    async () => {
      await sleep(100);

      const {
        data,
      } = await handlePostRequest(
        `https://building.plc.hr/api/c?m=Naslovnica${role ? role : ""}Gets`,
        [{ user: "igor", orgID: user.orgID }]
      );
      const parsedData = JSON.parse(data);
      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return false;
        });
      const selectData = formatData.map((item) => {
        const obj = { ...item };

        for (let key in item) {
          if (key === "ID") {
            Object.assign(obj, { value: item[key] });
          }
          if (key === "Naziv") {
            Object.assign(obj, { label: item[key] });
          }
        }
        return obj;
      });
      return selectData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );
  const onSubmit = (data) => {
    action(formatNaslovnicaData(data.NaslovnicaPredlozak));
    setOpen({ state: false });
    setOpenNaslovnica({ state: true, from: "add" });
  };
  return (
    <Modal
      className={"modal-dialog-centered " + (isFetching && "transparent")}
      size={"lg"}
      isOpen={open.state}
      toggle={() => setOpen({ ...open, state: !open.state })}
    >
      {isFetching || isLoading ? (
        <div className="d-flex mx-auto justify-content-center">
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : (
        <>
          <div className="modal-header">
            <h3 className="modal-title" id="exampleModalLabel">
              Odaberi naslovnicu
            </h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setOpen({ ...open, state: !open.state })}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="mx-auto">
            <h2 className="ml-4 ml-sm-0">
              Odaberite naslovnicu kao predložak za novu.
            </h2>
            <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md="12">
                    <Label for="NaslovnicaPredlozak">
                      Predložak naslovnice
                    </Label>
                    <Controller
                      as={<Select />}
                      id="NaslovnicaPredlozak"
                      placeholder="Odaberite predložak naslovnice"
                      name="NaslovnicaPredlozak"
                      onChange={(value) => {
                        if (value[1].action === "clear") {
                          return null;
                        } else return value[0];
                      }}
                      control={control}
                      rules={{
                        required: "Predložak je obvezno polje",
                        min: { value: 1, message: "Error" },
                      }}
                      options={data}
                    />
                    <ErrorMessage
                      as={Error}
                      errors={errors}
                      name="NaslovnicaPredlozak"
                    />
                  </Col>
                </Row>
              </Form>
            </Card>
            <Button
              className="mx-auto my-2 d-flex"
              color="primary"
              onClick={handleSubmit(onSubmit)}
            >
              Odaberi
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default PickTemplate;
