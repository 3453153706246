import React from "react";
import { authenticationService } from "../../services/authentication";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { makeStyles } from "@material-ui/core/styles";
import "./App.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    //marginLeft: "auto",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function ProfileMenu({ marginLeft, isMobile, color, user }) {
  let history = useHistory();

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  function changeRole() {
    const obj = { ...user, role: undefined };
    authenticationService.updateUser(obj);
    authenticationService.setNaslovnica({
      name: "",
      id: 0,
    });
    history.push("/");
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div
      className={classes.root}
      style={marginLeft ? { marginLeft: "auto" } : null}
    >
      <div>
        <IconButton
          aria-label="account of current user"
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ padding: 0 }}
        >
          <AccountCircle style={{ fill: color ? color : "white" }} />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          role={undefined}
          transition
          disablePortal
          className={isMobile ? "mobileMenu" : "regularMenu"}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "right bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {/*   <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                    <MenuItem onClick={changeRole}>Promjeni ulogu</MenuItem>

                    <MenuItem
                      onClick={(event) => {
                        handleClose(event);
                        authenticationService.setNaslovnica({
                          name: "",
                          id: 0,
                        });
                        authenticationService.logout();
                        history.push("/login");
                      }}
                    >
                      Odjava
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
