import React from "react";
import { authenticationService } from "../../services/authentication";
import { Button } from "reactstrap";
import { formatDateToString } from "../../utils/functions";
import DeleteModal from "./DeleteModal";
import style from "./MontazniDnevnik.module.scss";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "../Table/Table";
import FormModal from "./FormModal";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { handlePostRequest } from "../../helpers/handle-response";
import { useQuery, useMutation, queryCache } from "react-query";

const MontazniDnevnik = ({ user, activePage, size }) => {
  const [rowToEdit, setRowToEdit] = React.useState(0);
  const [rowToDelete, setRowToDelete] = React.useState({});
  const [reqError, setReqError] = React.useState({ state: false, message: "" });

  const [modals, setModals] = React.useState({
    Delete: false,
  });
  const { action, state } = useStateMachine(updateAction);

  const [open, setOpen] = React.useState({ state: false, from: "" });

  const { status, data, error, isLoading, isFetching, refetch } = useQuery(
    "montazni",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=MontazniGets",
        [{ user: "igor", naslovnicaID: activePage.id }]
      );
      const parsedData = JSON.parse(data);
      const formatData = parsedData.filter((item) => {
        if (new Date(item.Deleted) <= new Date(2000, 0, 1)) {
          return true;
        } else return null;
      });
      console.log(formatData);

      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          if (failureCount >= 3) {
            authenticationService.logout();
          } else return true;
        }
      },
    }
  );

  const [addMontazni] = useMutation(
    async (dataToAdd) => {
      const ZaposleniciCount = dataToAdd.Zaposlenici.map((item) => item.ID);
      const StrojeviCount = dataToAdd.Strojevi.map((item) => item.ID);
      console.log(ZaposleniciCount, StrojeviCount);
      const m = {
        ...dataToAdd,
        ID: open.from === "edit" ? rowToEdit : 0,
        OrganizationID: user.orgID,
        NaslovnicaID: activePage.id,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
        Zaposlenici: ZaposleniciCount,
        Strojevi: StrojeviCount,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=MontazniAddEdit",
        [{ user: "igor", m }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        console.log("is retry?", res, res === "Retry");
        if (res === "Retry" || res === undefined) {
          console.log("retry condition");
          queryCache.invalidateQueries("montazni");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          if (res.data === "False") {
            queryCache.invalidateQueries("montazni");
            setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
            setTimeout(() => {
              setReqError({ state: false, message: "" });
            }, 2000);
          } else {
            // refetch();
            queryCache.invalidateQueries("montazni");
            console.log(isFetching, "close modal fetching?");
            if (open.state /* && !isFetching */) {
              console.log("close modal");
              setOpen({ ...open, state: false });
            }
          }
        }
      },
    }
  );
  const [deleteMontazni] = useMutation(
    async () => {
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=MontazniDelete",
        [{ user: "igor", ID: rowToDelete.ID }]
      );
      return res;
    },
    {
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("montazni");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("montazni");
          if (modals.Delete && !isFetching) {
            setModals({ ...modals, Delete: false });
          }
        }
      },
    }
  );
  const cols = React.useMemo(
    () => [
      {
        accessor: "PocetakRada",
        className: "alignCenter",
        Header: "Početak rada",
        Cell: (props) => {
          return formatDateToString(props.value);
        },
      },
      {
        accessor: "ZavrsetakRada",
        className: "alignCenter",
        Header: "Završetak Rada",
        Cell: (props) => {
          return formatDateToString(props.value);
        },
      },
      {
        accessor: "Zaposlenici",
        Header: "Zaspolenici",
        className: "alignCenter",
      },
      {
        accessor: "Strojevi",
        className: "alignCenter",
        Header: "Strojevi",
      },
      {
        accessor: "UpisPoslovode",
        Header: "Upis poslovođe",
        className: "alignCenter",
      },
      {
        accessor: "UpisNadzora",
        Header: "Upis nadzora",
        className: "alignCenter",
      },
      {
        accessor: "UtrosakMaterijala",
        Header: "Utrošak materijala",
        className: "alignCenter",
      },

      {
        accessor: "Uredi",
        Header: "Uredi",
        className: "alignCenter",
        disableSortBy: true,
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.editButton}
                type="button"
                onClick={(e) => {
                  setRowToEdit(props.row.original.ID);
                  action(props.row.original);
                  console.log(props.row.original);
                  setOpen({ state: true, from: "edit" });
                }}
              >
                <i className="far fa-edit"></i>
              </button>
            );
          return null;
        },
      },
      {
        accessor: "Obrisi",
        Header: "Obriši",
        disableSortBy: true,
        className: "alignCenter",
        disableCaret: true,
        Cell: (props) => {
          if (props)
            return (
              <button
                className={style.deleteButton}
                type="button"
                onClick={() => {
                  setModals({ ...modals, Delete: true });
                  setRowToDelete(props.row.original);
                }}
              >
                <i className="far fa-trash-alt"></i>
              </button>
            );
          return null;
        },
      },
    ],
    [modals, action]
  );

  return (
    <>
      {isFetching ? (
        <div className="d-flex mx-auto justify-content-center mt-2">
          <CircularProgress size="4rem" color="primary" />
        </div>
      ) : (
        <div>
          <div className="main-content">
            <section className={style.buttons}>
              <Button
                color="primary"
                onClick={() => {
                  action({
                    ID: 0,
                    PocetakRada: new Date(),
                    Strojevi: null,
                    UpisNadzora: "",
                    UpisPoslovode: "",
                    Zaposlenici: null,
                    ZavrsetakRada: new Date(),
                    materijal: null,
                  });
                  setOpen({ from: "add", state: !open.state });
                }}
              >
                Novi unos
              </Button>
            </section>

            <Table
              columns={cols}
              initialSort={[{ id: "Naziv", desc: false }]}
              data={data}
            ></Table>
          </div>
        </div>
      )}
      <FormModal
        open={open}
        size={size}
        setOpen={setOpen}
        user={user}
        activePage={activePage}
        add={addMontazni}
      />
      {modals.Delete ? (
        <DeleteModal
          open={modals}
          setOpen={setModals}
          rowToDelete={rowToDelete}
          delFunc={deleteMontazni}
        ></DeleteModal>
      ) : null}
    </>
  );
};

export default MontazniDnevnik;
