import React from "react";
import { Modal, Button } from "reactstrap";
import { handlePostRequest } from "../../helpers/handle-response";

const DeleteModal = ({ open, setOpen, rowToDelete, deleteZaposlenik }) => {
  return (
    <Modal
      className="modal-dialog-centered"
      size={"lg"}
      isOpen={open.Delete}
      toggle={() => setOpen({ ...open, Delete: !open.Delete })}
    >
      <div className="modal-header">
        <h3 className="modal-title" id="exampleModalLabel">
          Obriši zaposlenika
        </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => setOpen({ ...open, Delete: !open.Delete })}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="mx-auto">
        <h2 className="ml-4 ml-sm-0">Jeste li sigurni da želite obrisati zaposlenika?</h2>
        <ul>
          <li>
            Ime: <span className="font-weight-bold"> {rowToDelete.Ime}</span>
          </li>
          <li>
            Prezime:{" "}
            <span className="font-weight-bold"> {rowToDelete.Prezime}</span>
          </li>
          <li>
            OIB: <span className="font-weight-bold"> {rowToDelete.OIB}</span>
          </li>
          <li>
            Struka:{" "}
            <span className="font-weight-bold"> {rowToDelete.Struka}</span>
          </li>
          <li>
            Razina obrazovanja:{" "}
            <span className="font-weight-bold">
              {" "}
              {rowToDelete.RazinaObrazovanja}
            </span>
          </li>
          <li>
            Stručna titula:{" "}
            <span className="font-weight-bold">
              {" "}
              {rowToDelete.StrucnaTitula}
            </span>
          </li>
          <li>
            Znanstvena titula:{" "}
            <span className="font-weight-bold">
              {" "}
              {rowToDelete.ZnanstvenaTitula}
            </span>
          </li>
        </ul>
        <Button
          className="mx-auto my-2 d-flex"
          color="danger"
          onClick={deleteZaposlenik}
        >
          Obriši
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
