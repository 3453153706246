import React from "react";
import { useForm, Controller, ErrorMessage } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import {
  Button,
  Form,
  Input,
  Card,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { Error } from "../../styles";
import Select from "react-select";
import { useQuery, useMutation, queryCache } from "react-query";
import {
  handlePostRequest,
  handleGetRequest,
} from "../../../helpers/handle-response";
import Imenovanja from "../../Akt/Imenovanja";

const customStyles = {
  control: (styles, { data }) => ({
    ...styles,
    boxShadow:
      " 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important",
    border: "0 !important",
    transition: " box-shadow 0.15s ease !important",
  }),
};
const Nadzor = ({ handleNext, handleBack, zaposlenici, user, size }) => {
  const { control, handleSubmit, errors, getValues, setValue } = useForm();
  const { action, state } = useStateMachine(updateAction);
  const [employees, setEmployees] = React.useState([]);
  const [aktModal, setAktModal] = React.useState({ from: "", open: false });
  const [reqError, setReqError] = React.useState({});
  const [addedAkt, setAddedAkt] = React.useState({ refresh: "" });

  const onSubmit = (data) => {
    console.log(data);
    action(data);
    handleNext();
  };
  const submitBack = () => {
    action(getValues());
    handleBack();
  };
  /*   const getLoc = (params) => {
    handleGetRequest("https://geocode.xyz", { params })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };
 */
  const setAkt = (data) => {
    //Find akt first
    const obj = data.find((item) => {
      if (item.naziv === addedAkt.naziv) {
        return item;
      }
    });
    setValue(aktModal.from, { ...obj, label: obj.naziv, value: obj.ID });
  };
  React.useEffect(() => {
    // filter zaposlenici by state.data
    const obj = state.data;
    if (obj.ID !== 0) {
      const ids = Object.keys(obj).map((key) => {
        if (key === "Nadzor1" || key === "Nadzor2" || key === "Nadzor3") {
          return obj[key].ID;
        }
        return null;
      });
      //console.log(ids, obj);
      const zaposleniciFiltered = zaposlenici.filter((item) => {
        //console.log(ids.includes(item.ID));
        if (ids.includes(item.ID)) {
          return false;
        } else return true;
      });
      //console.log(state.data, zaposlenici, ids, zaposleniciFiltered);
      setEmployees(zaposleniciFiltered);
    } else {
      setEmployees(zaposlenici);
    }
  }, [zaposlenici, setEmployees, state.data]);

  const { status, data, error, isFetching, isLoading } = useQuery(
    "aktovi",
    async () => {
      const {
        data,
      } = await handlePostRequest(
        "https://building.plc.hr/api/c?m=AktoviGets",
        [{ user: "igor", orgID: user.orgID }]
      );
      const parsedData = JSON.parse(data);

      const formatData = Object.keys(parsedData)
        .map((key) => parsedData[key])
        .filter((item) => {
          if (new Date(item.deleted) <= new Date(2000, 0, 1)) {
            return true;
          } else return null;
        })
        .map((item) => {
          return {
            ...item,
            value: item.ID,
            label: item.naziv,
          };
        });
      console.log(formatData);
      if (addedAkt.refresh === "refresh") {
        setAkt(formatData);
        setAddedAkt({ refresh: "" });
      }
      return formatData;
    },
    {
      retry: (failureCount, error) => {
        if (error === "Retry") {
          console.log(failureCount, error);
          return true;
        }
      },
    }
  );
  const [addAkt] = useMutation(
    async (dataToAdd) => {
      const a = {
        ID: 0,
        OrganizationID: user.orgID,
        Deleted: "",
        isDeleted: false,
        CreateIP: "",
        CreateTime: new Date(),
        CreateUser: user.username,
        ...dataToAdd,
      };
      const res = await handlePostRequest(
        "https://building.plc.hr/api/c?m=AktoviAddEdit",
        [{ user: "igor", a }]
      );
      return res;
    },
    {
      onSuccess: (data) => {
        console.log("is success", { isFetching, isLoading, data });
      },
      onError: (error) => {
        console.log(error);
      },
      onSettled: (res) => {
        if (res.data === "False") {
          queryCache.invalidateQueries("aktovi");
          setReqError({ state: true, message: "Pogreška. Pokušajte ponovo" });
          setTimeout(() => {
            setReqError({ state: false, message: "" });
          }, 2000);
        } else {
          // refetch();
          queryCache.invalidateQueries("aktovi");
          if (aktModal.open && !isFetching) {
            setAktModal({ ...aktModal, open: false });
          }
        }
      },
    }
  );
  return (
    <>
      <Card style={{ padding: 20, backgroundColor: "#F5F7F9" }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label for="Nadzor1">Nadzorni inženjer 1</Label>

                <Controller
                  id="Nadzor1"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.Nadzor1}
                  placeholder="Odaberite nadzornog inženjera 1"
                  name="Nadzor1"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else {
                      const filtered = employees.filter((item) => {
                        if (item.ID === value[0].value) {
                          return false;
                        } else return true;
                      });
                      setEmployees(filtered);
                      return value[0];
                    }
                  }}
                  control={control}
                  rules={{
                    required: "Nadzorni inženjer 1 je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={employees}
                />
                <ErrorMessage as={Error} errors={errors} name="Nadzor1" />
              </Col>
              <Col md="5">
                <Label for="Akt1">Akt o imenovanju 1</Label>
                <Controller
                  id="Akt1"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.Akt1}
                  placeholder="Odaberite akt"
                  name="Akt1"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else return value[0];
                  }}
                  control={control}
                  rules={{
                    required: "Akt 1 je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={data}
                />
                <ErrorMessage as={Error} errors={errors} name="Akt1" />
              </Col>
              <Col
                md="1"
                style={
                  size.width < 1024
                    ? { marginTop: "1vh" }
                    : errors.hasOwnProperty("Akt1")
                    ? { marginTop: "6vh" }
                    : { marginTop: "6vh" }
                }
              >
                <Button
                  size="sm"
                  title="Dodaj novi akt"
                  type="button"
                  className="mx-auto d-flex"
                  onClick={() => setAktModal({ from: "Akt1", open: true })}
                >
                  +
                </Button>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label for="Nadzor2">Nadzorni inženjer 2</Label>

                <Controller
                  id="Nadzor2"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.Nadzor2}
                  placeholder="Odaberite nadzornog inženjera 2"
                  name="Nadzor2"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else {
                      const filtered = employees.filter((item) => {
                        if (item.ID === value[0].value) {
                          return false;
                        } else return true;
                      });
                      setEmployees(filtered);
                      return value[0];
                    }
                  }}
                  control={control}
                  rules={{
                    required: "Nadzorni inženjer 2 je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={employees}
                />
                <ErrorMessage as={Error} errors={errors} name="Nadzor2" />
              </Col>
              <Col md="5">
                <Label for="Akt2">Akt o imenovanju 2</Label>
                <Controller
                  id="Akt2"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.Akt2}
                  placeholder="Odaberite akt"
                  name="Akt2"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else return value[0];
                  }}
                  control={control}
                  rules={{
                    required: "Akt 2 je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={data}
                />
                <ErrorMessage as={Error} errors={errors} name="Akt2" />
              </Col>
              <Col
                md="1"
                style={
                  size.width < 1024
                    ? { marginTop: "1vh" }
                    : errors.hasOwnProperty("Akt2")
                    ? { marginTop: "6vh" }
                    : { marginTop: "6vh" }
                }
              >
                <Button
                  size="sm"
                  title="Dodaj novi akt"
                  type="button"
                  className="mx-auto d-flex"
                  onClick={() => setAktModal({ from: "Akt2", open: true })}
                >
                  +
                </Button>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label for="Nadzor3">Nadzorni inženjer 3</Label>

                <Controller
                  id="Nadzor3"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.Nadzor3}
                  placeholder="Odaberite nadzornog inženjera 3"
                  name="Nadzor3"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else {
                      const filtered = employees.filter((item) => {
                        if (item.ID === value[0].value) {
                          return false;
                        } else return true;
                      });
                      setEmployees(filtered);
                      return value[0];
                    }
                  }}
                  control={control}
                  rules={{
                    required: "Nadzorni inženjer 3 je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={employees}
                />
                <ErrorMessage as={Error} errors={errors} name="Nadzor3" />
              </Col>
              <Col md="5">
                <Label for="Akt3">Akt o imenovanju 3</Label>
                <Controller
                  id="Akt3"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.Akt3}
                  placeholder="Odaberite akt"
                  name="Akt3"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else return value[0];
                  }}
                  control={control}
                  rules={{
                    required: "Akt 3 je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={data}
                />
                <ErrorMessage as={Error} errors={errors} name="Akt3" />
              </Col>
              <Col
                md="1"
                style={
                  size.width < 1024
                    ? { marginTop: "1vh" }
                    : errors.hasOwnProperty("Akt3")
                    ? { marginTop: "6vh" }
                    : { marginTop: "6vh" }
                }
              >
                <Button
                  size="sm"
                  title="Dodaj novi akt"
                  type="button"
                  className="mx-auto d-flex"
                  onClick={() => setAktModal({ from: "Akt3", open: true })}
                >
                  +
                </Button>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md="6">
                <Label for="GlavniNadzor">Glavni nadzorni inženjer</Label>

                <Controller
                  id="GlavniNadzor"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.GlavniNadzor}
                  placeholder="Odaberite glavnog nadzornog inženjera"
                  name="GlavniNadzor"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else return value[0];
                  }}
                  control={control}
                  rules={{
                    required: "Glavni nadzorni inženjer je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={zaposlenici}
                />
                <ErrorMessage as={Error} errors={errors} name="GlavniNadzor" />
              </Col>
              <Col md="5">
                <Label for="AktGlavni">Akt o imenovanju</Label>
                <Controller
                  id="AktGlavni"
                  as={<Select styles={customStyles} />}
                  defaultValue={state.data.AktGlavni}
                  placeholder="Odaberite akt"
                  name="AktGlavni"
                  onChange={(value) => {
                    if (value[1].action === "clear") {
                      return null;
                    } else return value[0];
                  }}
                  control={control}
                  rules={{
                    required: "Akt o imenovanju je obvezno polje",
                    min: { value: 1, message: "Error" },
                  }}
                  options={data}
                />
                <ErrorMessage as={Error} errors={errors} name="AktGlavni" />
              </Col>
              <Col
                md="1"
                style={
                  size.width < 1024
                    ? { marginTop: "1vh" }
                    : errors.hasOwnProperty("AktGlavni")
                    ? { marginTop: "6vh" }
                    : { marginTop: "6vh" }
                }
              >
                <Button
                  size="sm"
                  title="Dodaj novi akt"
                  type="button"
                  className="mx-auto d-flex"
                  onClick={() => setAktModal({ from: "AktGlavni", open: true })}
                >
                  +
                </Button>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row style={{ justifyContent: "center" }}>
              <Col md="6">
                <Label for="NazivSjediste">Naziv i sjedište</Label>
                <Controller
                  id="NazivSjediste"
                  as={
                    <Input
                      className="form-control-alternative"
                      placeholder="Naziv i sjedište osobe"
                      type="text"
                    />
                  }
                  defaultValue={state.data.NazivSjediste}
                  name="NazivSjediste"
                  control={control}
                  rules={{
                    required: "Naziv i sjedište osobe je obvezno polje",
                  }}
                />
                <ErrorMessage as={Error} errors={errors} name="NazivSjediste" />
              </Col>
            </Row>
          </FormGroup>
          <Row style={{ marginTop: 20 }}>
            <Col>
              <Button onClick={submitBack}>Natrag</Button>
              <Button color="primary" type="submit">
                Sljedeći
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <Imenovanja
        aktovi={data}
        aktModal={aktModal}
        setAktModal={setAktModal}
        add={addAkt}
        setAkt={setAddedAkt}
      />
    </>
  );
};

export default Nadzor;
